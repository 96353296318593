import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Textarea,
  Flex,
  VStack,
  InputRightElement,
  IconButton,
  Input,
  Box,
  Button,
  Select,
  InputGroup,
  FormLabel,
  Tag,
  FormControl,
  p,
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import CreatableSelect from "react-select/creatable";
import endpoints from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { toast } from "react-toastify";

const AddCampaignDrawer = ({ onClose, isOpen ,getData}) => {
  const [targetAudience, setTargetAudience] = useState([]);
  const firstField = React.useRef();
  const [uploadedFiles, setUploadedFiles] = useState([
    
  ]);

  const [formValues, setFormValues] = useState({
    name: "",
    campaignType: "",
    dealValue: "",
    currency: "",
    period: "",
    periodValue: "",
    description: "",
    attachment: uploadedFiles,
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle change for text inputs
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });

    setFormErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: "",
      }));
  };
  //handle change

  const handleSelectChange = (newValue) => {
    setTargetAudience(newValue);
    setFormErrors((prevErrors) => ({
        ...prevErrors,
        targetAudience: "",
      }));
  };

  const handleDelete = (fileName) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const formattedValues = {
          ...formValues,
          targetAudience: formValues.targetAudience ? formValues.targetAudience.join("|") : "",
          attachment: formValues.attachment ? formValues.attachment.join("|") : "",
          status: formValues.status || "Active", // Ensure default status is set
        };
        const res = await CallAPI(endpoints.createCampaigns, formattedValues); // Replace with your actual endpoint
  
        if (res?.code === 200) {
          console.log(res);
          toast.success(res?.message || 'campaign added successfully!');
          onClose(); // Close the form/modal
          getData()
          setFormValues({}); // Reset form values if needed
        } else {
          toast.error(res?.message || 'Failed to add lead.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to add lead due to an error.');
      } finally {
      }
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      initialFocusRef={firstField}
      p="20px"
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Campaign</DrawerHeader>

        <DrawerBody>
          <Stack spacing="24px">
            <Box>
              <FormLabel htmlFor="name">
                Name <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                ref={firstField}
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Enter campaign name"
                isRequired
              />
              {formErrors.name &&  < p style={{color:"red"}}>{formErrors.name}</p>}
            </Box>

            <Box>
              <FormLabel htmlFor="campaignType">
                Campaign Type <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Select
                id="campaignType"
                name="campaignType"
                value={formValues.campaignType}
                onChange={handleChange}
                placeholder="Choose"
                isRequired
              >
                <option value="social">Social Marketing</option>
                <option value="content">Content Marketing</option>
                <option value="branding">Branding</option>
              </Select>
              {formErrors.campaignType &&  < p style={{color:"red"}}>{formErrors.campaignType}</p>}
            </Box>

            <Flex direction="column" gap="4">
  <Flex gap="4">
    <Box flex="1">
      <FormLabel htmlFor="dealValue">
        Deal Value <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="dealValue"
        name="dealValue"
        value={formValues.dealValue}
        onChange={handleChange}
        placeholder="Enter deal value"
        isRequired
      />
      {formErrors.dealValue &&  < p style={{color:"red"}}>{formErrors.dealValue}</p>}
    </Box>

    <Box flex="1">
      <FormLabel htmlFor="currency">
        Currency <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="currency"
        name="currency"
        value={formValues.currency}
        onChange={handleChange}
        placeholder="Currency"
        isRequired
      />
      {formErrors.currency &&  < p style={{color:"red"}}>{formErrors.currency}</p>}
    </Box>
  </Flex>

  <Flex gap="4">
    <Box flex="1">
      <FormLabel htmlFor="period">
        Period <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="period"
        name="period"
        value={formValues.period}
        onChange={handleChange}
        placeholder="Period"
        isRequired
      />
      {formErrors.period &&  < p style={{color:"red"}}>{formErrors.period}</p>}
    </Box>

    <Box flex="1">
      <FormLabel htmlFor="periodValue">
        Period Value<span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="periodValue"
        name="periodValue"
        value={formValues.periodValue}
        onChange={handleChange}
        placeholder="Period Value"
        isRequired
      />
      {formErrors.periodValue &&  < p style={{color:"red"}}>{formErrors.periodValue}</p>}
    </Box>
  </Flex>
</Flex>

            <Box>
              <FormLabel htmlFor="targetAudience">
                Target Audience <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <CreatableSelect
                isMulti
                options={[
                  { value: "Small Business", label: "Small Business" },
                  { value: "Corporate Companies", label: "Corporate Companies" },
                ]}
                value={targetAudience}
                onChange={handleSelectChange}
                placeholder="Select or type target audience"
              />
              {formErrors.targetAudience &&  < p style={{color:"red"}}>{formErrors.targetAudience}</p>}
            </Box>

            <Box>
              <FormLabel htmlFor="description">
                Description <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Textarea
                id="description"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                placeholder="Enter description"
                isRequired
              />
              {formErrors.description &&  < p style={{color:"red"}}>{formErrors.description}</p>}
            </Box>

            <Box>
              <FormLabel htmlFor="attachment">Attachment 
                <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box
    border="1px solid #D6D6D6"
    width="100%"
    height="80px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
      <Button
        as="span"
        variant="outline"
        colorScheme="blue"
        leftIcon={<BiUpload />}
      >
        Upload File
      </Button>
    </label>
    <input
      id="file-upload"
      type="file"
      style={{ display: "none" }}
      onChange={(e) => {
        // handle file upload here
        const files = Array.from(e.target.files);
        setUploadedFiles(files.map((file) => ({ name: file.name, size: `${(file.size / (1024 * 1024)).toFixed(2)} MB` })));
      }}
    />
  </Box>
              {formErrors.attachment &&  < p style={{color:"red"}}>{formErrors.attachment}</p>}
            </Box>

            <Box>
              <FormLabel>Uploaded Files</FormLabel>
              <VStack spacing={2}>
                {uploadedFiles.map((file) => (
                  <Box key={file.name} display="flex" alignItems="center">
                    <InputGroup key={file.name} mb={2} width="100%">
                      <Input
                        isReadOnly
                        value={`${file.name} (${file.size})`}
                        pr="30px"
                        minW={"400px"}
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Delete file"
                          icon={<AiOutlineDelete />}
                          onClick={() => handleDelete(file.name)}
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                ))}
              </VStack>
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Button type="button" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" colorScheme="red" onClick={handleSubmit} isLoading={isSubmitting}>
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddCampaignDrawer;
