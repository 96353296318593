import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import { FiFilter } from "react-icons/fi";
import companyicon from "../Dashboard/icons/companyicon.png";
import ReactApexChart from "react-apexcharts";

const ContactReport = () => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [filter, setFilter] = useState("Active Campaign");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const leadManagementData = [
    {
      id: 1,
      leadName: "John Doe",
      companyName: "ABC Corp",
      phone: "123-456-7890",
      email: "john.doe@abccorp.com",
      leadStatus: "Success",
      createdDate: "25 Sep 2023",
      leadOwner: "Alice Johnson",
      location: "New York, USA",
      avatar: companyicon,
    },
    {
      id: 2,
      leadName: "Jane Smith",
      companyName: "XYZ Inc",
      phone: "098-765-4321",
      email: "jane.smith@xyzinc.com",
      leadStatus: "Pending",
      createdDate: "03 Oct 2023",
      location: "New York, USA",
      avatar: companyicon,
      leadOwner: "Bob Lee",
    },
    // Add more lead records as needed
  ];

  const filteredData = leadManagementData.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const chartdata = {
    series: [
      {
        name: "Value 1",
        data: [20, 40, 60, 90, 50, 70, 30, 50, 60, 30, 40, 30],
      },
    ],
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };

  const options = {
    chart: {
      type: "area",
      height: 400,
      toolbar: { show: false },
    },
    stroke: { width: 2, curve: "smooth" },
    colors: ["#4285F4", "#FF5722", "#FFC107"],
    xaxis: {
      categories: chartdata.categories,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: { position: "top" },
  };

  const doughnutChartOptions = {
    chart: { type: "donut" },
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    legend: { position: "bottom" },
  };
  const doughnutChartData = [44, 58, 41, 17];

  const LeadManagementTable = ({ data }) => (
    <Table
      variant="simple"
      border="1px solid"
      borderColor="gray.200"
      size={{ base: "sm", md: "md" }}
    >
      <Thead>
        <Tr bg="#FAFAFA">
          <Th>
            <Checkbox />
          </Th>
          <Th>Lead Name</Th>
          <Th>Company Name</Th>
          <Th>Phone</Th>
          <Th>Email</Th>
          <Th>Lead Status</Th>
          <Th>Created Date</Th>
          <Th>Lead Owner</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((lead) => (
          <Tr key={lead.id}>
            <Td>
              <Checkbox />
            </Td>
            <Td fontSize="16px" fontWeight="500" color="#26262A">
              {lead.leadName}
            </Td>
            <Td>
              <Flex align="center">
                <Avatar src={lead.avatar} size="sm" mr="2" />
                <Box>
                  <Text fontWeight="medium" fontSize="sm">
                    {lead.companyName}
                  </Text>
                  <Text fontSize="xs" color="gray.500">
                    {lead.location}
                  </Text>
                </Box>
              </Flex>
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.phone}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.email}
            </Td>
            <Td>
              <Badge
                bg={"orange"}
                borderRadius="md"
                p="2"
                textAlign="center"
                fontWeight="600"
                color="white"
                fontFamily="Poppins"
              >
                {lead.leadStatus}
              </Badge>
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.createdDate}
            </Td>
            <Td fontSize="14px" fontWeight="500" color="#26262A">
              {lead.leadOwner}
            </Td>
            <Td>
              <IconButton
                icon={<FaEllipsisV />}
                variant="ghost"
                aria-label="Options"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Box p={{ base: 3, md: 5 }} borderWidth="1px" borderRadius="md" bg="white">
      <HStack
        justifyContent="space-between"
        mb="6"
        alignItems="center"
        flexWrap="wrap"
      >
        <Input
          placeholder="Search Contacts"
          w={{ base: "100%", md: "300px" }}
          mb={{ base: 2, md: 0 }}
        />
        <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
          Download Report
        </Button>
      </HStack>

      <HStack spacing={{ base: 2, md: 6 }} mb="6" flexWrap="wrap">
        <Box
          width={{ base: "100%", md: "40%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
        >
          <Flex
            justify="space-between"
            flexDirection={{base:"column",md:"row"}}
            gap={"4px"}
            align="center"
            mb="2"
          >
            <Text
              as="h2"
              fontFamily="Poppins, sans-serif"
              fontWeight="500"
              fontSize="18px"
              lineHeight="27px"
            >
              Contacts By Year
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <ReactApexChart
            options={options}
            series={chartdata.series}
            type="line"
            height={350}
          />
        </Box>

        <Box
          width={{ base: "100%", md: "50%" }}
          p="5"
          borderWidth="1px"
          borderRadius="md"
        >
          {/* <Flex justify="space-between" align="center" mb="2">
           */}
          <Flex
            justify="space-between"
            flexDirection={{base:"column",md:"row"}}
            gap={"4px"}
            align="center"
            mb="2"
          >
            <Text
              as="h2"
              fontFamily="Poppins, sans-serif"
              fontWeight="500"
              fontSize="18px"
              lineHeight="27px"
            >
              Contacts By Source
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <Chart
            options={doughnutChartOptions}
            series={doughnutChartData}
            type="donut"
            height={350}
          />
        </Box>
      </HStack>
      <Box overflowX="auto">
        <LeadManagementTable data={paginatedData} />

        <Flex justify="space-between" align="center" mt="4">
          <Select
            width="auto"
            onChange={handleItemsPerPageChange}
            value={itemsPerPage}
            size="sm"
          >
            <option value={5}>5 per page</option>
            <option value={10}>10 per page</option>
            <option value={20}>20 per page</option>
          </Select>
          <HStack>
            <IconButton
              icon={<ChevronLeftIcon />}
              isDisabled={currentPage === 1}
              onClick={() => handlePageChange(-1)}
              size="sm"
            />
            <Text>{currentPage}</Text>
            <IconButton
              icon={<ChevronRightIcon />}
              isDisabled={currentPage === totalPages}
              onClick={() => handlePageChange(1)}
              size="sm"
            />
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default ContactReport;
