import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  Badge,
  Icon,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { BsSend } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { RiAttachment2 } from 'react-icons/ri';
import Message from './Message';
import { useSendMessages } from '../hooks/useSendMessages';
import { useGetMessages } from '../hooks/useGetMessages';
import { useListenMessages } from '../hooks/useListenMessages';
const Messages = () => {
  const data = JSON.parse(localStorage.getItem('userData'));
  const SenderId = data?.id;
  const { messages } = useGetMessages();
  // console.log('messages ', messages);

  useListenMessages();
  const [message, setMessage] = useState('');
  const { loading, sendMessage } = useSendMessages();
  const lastMessageRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [messages]);
  const [input, setInput] = useState({
    name: 'abhinav',
    id: SenderId,
    message: message,
    isGroup: false,
  });
  useEffect(() => {
    setInput((prev) => ({
      ...prev,
      message,
      id: SenderId || prev.id, // Make sure SenderId is always updated in the input state
    }));
  }, [message, SenderId]);
  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (!input) return;

    await sendMessage(input);
    setInput({
      ...input,
      message: '',
    });
    setMessage('');
  };
  const customScrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f0f0f0',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f0f0f0',
  };
  return (
    <Box
      p={4}
      pr={0}
      h="90%"
      w="100%"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Flex
        direction="column"
        justifyContent="space-between"
        p={4}
        pr={0}
        w="full"
        h="90%"
        bg="white"
      >
        <Flex align="center" my={3} ml={12} w="80%">
          <Box flex="1" h="1px" bg="#B6B6B6" />
          <Text px={3} fontSize="16px" fontWeight={600} m={0} color="#757575">
            Today
          </Text>
          <Box flex="1" h="1px" bg="#B6B6B6" />
        </Flex>
        <Box
          h="90%"
          m={0}
          p={0}
          maxH={'50vh'}
          overflowY={'scroll'}
          sx={customScrollbarStyles}
        >
          {messages.map((message, index) => (
            <div key={index} ref={lastMessageRef}>
              <Message key={message.id} authUser={data} message={message} />
            </div>
          ))}
        </Box>

        {/* Repeat the above structure for additional messages */}
      </Flex>
      <InputGroup mx="auto" mt={10} w="80%">
        <InputRightElement
          children={<Icon onClick={handleSubmit} boxSize={4} as={FiSend} />}
        />
        <InputRightElement
          pointerEvents="none"
          mr={6}
          children={<Icon boxSize={5} as={RiAttachment2} />}
        />
        <Input
          type="text"
          name="message"
          placeholder="Send Messages"
          border="1px"
          borderColor="#D1202A"
          borderRadius="full"
          value={input.message}
          onChange={(e) =>
            setInput((prev) => ({
              ...prev,
              [e.target.name]: e.target.value,
            }))
          }
          pr={10} // Add padding to the right to make space for the icon
        />
      </InputGroup>
    </Box>
  );
};

export default Messages;
