
import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Input,
  Box,
  Button,
  Select,
  Radio,
  RadioGroup,
  HStack,
  VStack,Icon,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Switch,
  Text,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa"; // For upload icon
import endpoints from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { toast } from "react-toastify";

const AddNewUser = ({ onClose, isOpen ,getData}) => {
  const [formValues, setFormValues] = useState({
    name: "",
    userName: "",
    email: "",
    image:null,
    role: "",
    phone1: "",
    phone2: "" | undefined,
    password: "",
    repeatPassword: "",
    status: "Active",
    location: "",
    emailOptOut: false,
  });
  const [errors, setErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(null); // To store preview URL

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormValues({ ...formValues, image: file }); // Update the image field
      setPreviewImage(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const handleRadioChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleToggle = (name) => {
    setFormValues({ ...formValues, [name]: !formValues[name] });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== "phone2" && key !== "emailOptOut" && key !== "image") {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
 
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const res = await CallAPI(endpoints.createUsers, formValues); // Replace with your actual endpoint
  
        if (res?.code === 200) {
          console.log(res);
          toast.success(res?.message || 'Lead added successfully!');
          onClose(); 
          setFormValues({});
          getData()
        } else {
          toast.error(res?.message || 'Failed to add lead.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to add lead due to an error.');
      } finally {
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New User</DrawerHeader>

        <DrawerBody>
          <Stack spacing="20px">
          <HStack align="start" spacing={2}>
              <Box
                border="2px dashed #E2E8F0"
                borderRadius="md"
                width="100px"
                height="100px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                overflow="hidden" // Ensures image fits inside the box
                bg="gray.50"
              >
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                ) : (
                  <Icon as={FaUpload} boxSize={6} color="gray.400" />
                )}
              </Box>

              {/* Upload Button */}
              <Box
                border="2px solid #E2E8F0"
                borderRadius="md"
                textAlign="center"
                p="10px"
                width="100%"
                justifyContent="center"
              >
                <Button as="label" leftIcon={<Icon as={FaUpload} />} colorScheme="blue" variant="ghost" size="sm">
                  Upload File
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    display="none"
                  />
                </Button>

                {/* File type and size instructions */}
                <Text fontSize="sm" color="gray.500">
                  JPG, GIF, or PNG. Max size of 800KB
                </Text>
              </Box>
            </HStack>


            <SimpleGrid columns={2} spacingX="20px" spacingY="20px">
              {[
                { label: "Name", name: "name", placeholder: "Enter name" },
                { label: "User Name", name: "userName", placeholder: "Enter user name" },
                { label: "Email", name: "email", placeholder: "Enter email" },
                { label: "Role", name: "role", placeholder: "Enter role" },
                { label: "Phone 1", name: "phone1", placeholder: "Enter primary phone" },
                { label: "Phone 2", name: "phone2", placeholder: "Enter secondary phone" },
                { label: "Password", name: "password", placeholder: "Enter password", type: "password" },
                { label: "Repeat Password", name: "repeatPassword", placeholder: "Repeat password", type: "password" },
              ].map((field) => (
                <FormControl key={field.name} isInvalid={errors[field.name]}>
                   <Flex justifyContent="space-between" align="center">
  <Box>
    <FormLabel>{field.label}</FormLabel>
  </Box>
  {field.name === "email" ? (
    <Box>
      <FormControl>
        <HStack align="center">
          <FormLabel fontSize={"14px"} mb="0">Email Opt-Out</FormLabel>
          <Switch
            size="sm"
            isChecked={formValues.emailOptOut}
            onChange={() => handleToggle("emailOptOut")}
          />
        </HStack>
      </FormControl>
    </Box>
  ) : null}
</Flex>  
                  <Input
                    name={field.name}
                    value={formValues[field.name]}
                    onChange={handleChange}
                    placeholder={field.placeholder}
                    type={field.type || "text"}
                  />
                  <FormErrorMessage>{errors[field.name]}</FormErrorMessage>
                </FormControl>
              ))}

            
              <FormControl isInvalid={errors.status}>
                <FormLabel >Status</FormLabel>
                <RadioGroup
                  name="status"
                  value={formValues.status}
                  onChange={(value) => handleRadioChange("status", value)}
                >
                  <HStack spacing="24px">
                    <Radio value="Active" size="sm">Active</Radio>
                    <Radio value="Inactive" size="sm">Inactive</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{errors.status}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.location}>
                <FormLabel >Location</FormLabel>
                <Input
                  name="location"
                    value={formValues.location}
                    onChange={handleChange}
                    placeholder="Choose location"
                    type={"text"}
                  />
             
              
                <FormErrorMessage>{errors.location}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" color="#00000099" mr={3} onClick={onClose} >
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleSubmit}>
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddNewUser;
