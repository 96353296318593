import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  Select,
  useDisclosure,
  IconButton,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiFilter } from "react-icons/fi";
import CompanyProfile from "../Header/CompanyProfile";
import AddNewLead from "./AddLeadManagement";
import companyicon from "../Dashboard/icons/companyicon.png"
import endpoints from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { format } from "date-fns"; // Use this to format dates if needed
import moment from "moment";

const statusColors = {
  Success: "green",
  Pending: "orange",
  Bounced: "red",
  Running: "blue",
  Paused: "yellow",
};

const LeadManagementTable = ({ data }) => (
  <Table variant="simple" border="1px solid" borderColor="gray.200">
    <Thead>
      <Tr bg="#FAFAFA">
        <Th>
          <Checkbox />
        </Th>
        <Th className="table-header-style">Lead Name</Th>
        <Th className="table-header-style">Company Name</Th>
        <Th className="table-header-style">Phone</Th>
        <Th className="table-header-style">Email</Th>
        <Th className="table-header-style">Lead Status</Th>
        <Th className="table-header-style">Created Date</Th>
        <Th className="table-header-style">Lead Owner</Th>
        <Th className="table-header-style">Action</Th>
      </Tr>
    </Thead>

    {/* Table Body */}
    <Tbody>
      {data.map((lead) => (
        <Tr key={lead.uuid}>
          <Td>
            <Checkbox />
          </Td>
          <Td fontSize="16px" fontWeight="500" color="#26262A">
            {lead.leadName}
          </Td>
          <Td>
            <Flex align="center">
              <Avatar src={lead.avatar} size="sm" mr="2" />
              <Box mt="2">
                <Text fontWeight="medium" fontSize="sm" mb="0.5">
                  {lead.companyName}
                </Text>
                <Text fontSize="xs" color="gray.500" lineHeight="2">
                  {lead.location}
                </Text>
              </Box>
            </Flex>
          </Td>
          <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
            {lead.phone}
          </Td>
          <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
            {lead.email}
          </Td>
          <Td>
            <Badge
              bg={"orange"}
              borderRadius="md"
              p="2"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="auto"
              fontWeight="600"
              color="white"
              fontFamily="Poppins"
            >
              {lead.status}
            </Badge>
          </Td>
          <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
          {moment(lead.createdAt).format('DD-MM-YYYY')}
          </Td>
          <Td fontSize="14px" fontWeight="500" color="#26262A">
            {lead.owner}
          </Td>
          <Td>
            <IconButton
              icon={<FaEllipsisV />}
              variant="ghost"
              aria-label="Options"
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);


const LeadManagements = () => {
  const [filter, setFilter] = useState(""); // For search filtering
  const [sortField, setSortField] = useState(""); // For sorting
  const [startDate, setStartDate] = useState(null); // Filter by date range
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [leadManagementData, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await CallAPI(endpoints.getleads); // Replace with your actual endpoint
      if (res?.code === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Filtering logic
  const filteredData = leadManagementData.filter((lead) => {
    const matchesFilter =
      filter === "" ||
      lead.leadName.toLowerCase().includes(filter.toLowerCase());
    const matchesDateRange =
      (!startDate || new Date(lead.createdAt) >= startDate) &&
      (!endDate || new Date(lead.createdAt) <= endDate);
    return matchesFilter && matchesDateRange;
  });

  // Sorting logic
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortField === "name") {
      return a.leadName.localeCompare(b.leadName);
    } else if (sortField === "date") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    return 0; // No sorting
  });

  // Pagination logic
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <Box p={2} overflowX="auto">
      <AddNewLead isOpen={isOpen} onClose={onClose} getData={getData} />
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex
          align="center"
          justifyContent="space-between"
          p="3"
          borderRadius="md"
          width="100%"
          gap={10}
          direction={{ base: "column", md: "row" }}
        >
          <CompanyProfile />
        </Flex>
      </Box>
      <Box p={4} m="2" borderWidth="1px" borderRadius="md" bg="white">
        <Box
          display="flex"
          justifyContent="space-between"
          mb={4}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Input
            placeholder="Search Lead"
            width={{ base: "100%", md: "300px" }}
            mb={{ base: 3, md: 0 }}
          />
          <Box
            display="flex"
            gap={4}
            justifyContent={{ base: "flex-start", md: "flex-end" }}
            width="100%"
          >
            <Menu>
              <MenuButton
                as={Button}
                colorScheme="white"
                border="1px solid #E8E8E8"
                color="#262A2A"
                rightIcon={<ChevronDownIcon />}
                mr={2}
                fontSize="14px"
                fontWeight="400"
              >
                Export
              </MenuButton>
              <MenuList>
                <MenuItem>Export as CSV</MenuItem>
                <MenuItem>Export as PDF</MenuItem>
                <MenuItem>Export as Excel</MenuItem>
              </MenuList>
            </Menu>
            <Button colorScheme="red" onClick={onOpen}>
              Add Leads
            </Button>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          mb={4}
          flexDirection={{ base: "column", md: "row" }}
        >
          <HStack mb={{ base: 4, md: 0 }}>
            <Select
              fontSize="14px"
              fontWeight="400"
              placeholder="Sort by"
              width="150px"
            >
              <option value="name">Name</option>
              <option value="date">Date</option>
            </Select>
            <Box border="1px solid #E8E8E8" padding="6px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
            <Box border="1px solid #E8E8E8" padding="6px">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
              />
            </Box>
          </HStack>
          <HStack justifyContent={{ base: "flex-start", md: "flex-end" }}>
            <Button color="blue" fontSize="14px" fontWeight="600">
              Manage Columns
            </Button>
            <Button
              colorScheme="white"
              border="1px solid #E8E8E8"
              color="#262A2A"
              leftIcon={<FiFilter />}
            >
              Filter
            </Button>
          </HStack>
        </Box>
      <Box overflowX="auto">
        <LeadManagementTable data={paginatedData} />
        </Box>
        <HStack
          justifyContent="space-between"
          mt={4}
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
        >
          <HStack>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>
          <HStack>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1}
              leftIcon={<ChevronLeftIcon />}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === totalPages}
              rightIcon={<ChevronRightIcon />}
            >
              Next
            </Button>
          </HStack>
        </HStack>
        </Box>
      </Box>
  );
};

export default LeadManagements;

