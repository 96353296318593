import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();
export { AuthContext };

const useAuthContext = () => {
  return useContext(AuthContext);
};
export { useAuthContext };

const AuthContextProvider = ({ children }) => {

  const [authUser, setAuthUser] = useState(
    JSON.parse(localStorage.getItem('userData')) || null
  );

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };
