import React, { useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Select,
  Icon,
} from '@chakra-ui/react';
import { FaShareSquare } from 'react-icons/fa';

import CompanyProfile from '../Header/CompanyProfile';
import RecentlyCreatedLeads from './RecentlyCreatedLeads';
import BudgetOverview from './BudgetOverview';
import LeadsCards from './LeadsCards';
import CampaignChart from './Campaignsgraph';
import TopPerformingPlatforms from './TopPerformPlatforms';
import UpcomingSessions from './UpcomingSessions';
import './Dashboard.css';


const DashboardPage = () => {
  // Initial budget data
  // const initialBudgetData = {
  //   filter: 'All',
  //   spend: 200,
  //   remaining: 800,
  //   details: [
  //     { month: 'June', amount: 3000, spent: 1800, remaining: 1200 },
  //     { month: 'July', amount: 2000, spent: 1200, remaining: 800 },
  //     { month: 'August', amount: 3000, spent: 1800, remaining: 1200 },
  //     { month: 'September', amount: 3000, spent: 1800, remaining: 1200 },
  //     { month: 'October', amount: 2000, spent: 1200, remaining: 800 },
  //     { month: 'November', amount: 2000, spent: 1200, remaining: 800 },
  //   ],
  // };

  // const [budgetData, setBudgetData] = useState(initialBudgetData);
  // const [viewAllLeads, setViewAllLeads] = useState(false);

  // const handleBudgetFilterChange = (e) => {
  //   const filter = e.target.value;
  //   let updatedDetails;
  //   // Budget filter logic
  //   switch (filter) {
  //     case 'Daily':
  //       updatedDetails = [
  //         { day: '1', amount: 100, spent: 30, remaining: 70 },
  //         { day: '2', amount: 100, spent: 60, remaining: 40 },
  //         // More days...
  //       ];
  //       break;
  //     case 'Monthly':
  //       updatedDetails = initialBudgetData.details;
  //       break;
  //     case 'Yearly':
  //       updatedDetails = [
  //         { year: '2020', amount: 24000, spent: 14400, remaining: 9600 },
  //         { year: '2021', amount: 36000, spent: 21600, remaining: 14400 },
  //         // More years...
  //       ];
  //       break;
  //     default:
  //       updatedDetails = initialBudgetData.details;
  //       break;
  //   }

  //   setBudgetData((prevData) => ({
  //     ...prevData,
  //     filter,
  //     details: updatedDetails,
  //   }));
  // };

  return (
    <Box width="100%" p="10px">
      {/* Header Section */}
      <Flex
        align="center"
        justifyContent="space-between"
        p={"3"}
        borderRadius="md"
        width="100%"
        gap={10}
        direction={{ base: 'column', md: 'row' }}
      >
        {/* Left Side: Company Profile */}
        <CompanyProfile />

        {/* Right Side: Filters and Export */}
        <Flex
          align="center"
          gap={3}
          direction={{ base: 'column', md: 'row' }}
          width={{ base: '100%', md: 'auto' }}
        >
          <Input type="date" size="sm" borderRadius="md" bg="white" />
          <Select placeholder="Types" size="sm" borderRadius="md" bg="white">
            <option>Type 1</option>
            <option>Type 2</option>
          </Select>
          <Select placeholder="Data Scope" size="sm" borderRadius="md" bg="white">
            <option>Scope 1</option>
            <option>Scope 2</option>
          </Select>
          <Box p={1} border="1px solid #D6D6D6" borderRadius="md">
            <Flex align="center" gap={2}>
              <Icon as={FaShareSquare} boxSize={3} />
              <h6>Export</h6>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      {/* Leads Cards Section */}
      <LeadsCards />

      {/* Budget Overview and Recently Created Leads */}
      <Flex
        justify="space-between"
        pt="20px"
        pb="20px"
        gap="30px"
        flexWrap="wrap"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box boxShadow="md" p="5" width={{ base: '100%', lg: '47.5%' }} borderRadius="md" bg="white" flex="1">
          <BudgetOverview />
        </Box>
        <Box boxShadow="md" p="5" width={{ base: '100%', lg: '47.5%' }} borderRadius="md" bg="white" flex="1">
          <RecentlyCreatedLeads />
        </Box>
      </Flex>

      {/* Campaign Chart Section */}
      <CampaignChart />

      {/* Upcoming Sessions and Top Performing Platforms */}
      <Flex
        justify="space-between"
        pt="20px"
        mt="20px"
        gap="30px"
        flexWrap="wrap"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box boxShadow="md" p="5" width={{ base: '100%', lg: '47.5%' }} borderRadius="md" bg="white" flex="1">
          <UpcomingSessions />
        </Box>
        <Box boxShadow="md" p="5" width={{ base: '100%', lg: '47.5%' }} borderRadius="md" bg="white" flex="1">
          <TopPerformingPlatforms />
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardPage;
