import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import HeaderChat from './HeaderChat';
import Messages from './Messages';
import { useConversation } from '../zustand/useConversation';
const MessageContainer = () => {
  const { selectedConversation, setSelectedConversation } = useConversation();
  return (
    <Box
      w="50%"
      border="1px"
      borderTop={'0px'}
      p={0}
      m={0}
      borderColor="#CCCBCB"
      height="95vh"
      // borderBottom={'0px'}
      bg="#FFFFFF"
    >
      <Flex
        w="100%"
        h="100%"
        flexDirection="column"
        justifyContent={'space-between'}
      >
        <HeaderChat selectedConversation={selectedConversation} />
        <Messages />
      </Flex>
    </Box>
  );
};

export default MessageContainer;
