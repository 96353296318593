import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthContextProvider } from './Components/CommunityGroup/context/AuthContext';
import { SocketContextProvider } from './Components/CommunityGroup/context/SocketContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId =
  '970166821874-tkfdgp6f036dmgjj1j9jj23a36j5n5kl.apps.googleusercontent.com';

root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <ChakraProvider>
      <React.StrictMode>
        <AuthContextProvider>
          <SocketContextProvider>
            <App />
          </SocketContextProvider>
        </AuthContextProvider>
      </React.StrictMode>
    </ChakraProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
