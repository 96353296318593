import React, { useState ,useEffect} from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  Select,
  useDisclosure,
  IconButton,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiFilter } from "react-icons/fi";
import CompanyProfile from "../Header/CompanyProfile";
import companyicon from "../Dashboard/icons/companyicon.png";
import AddNewUser from "./AddUser";
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";
import moment from "moment";


const statusColors = {
  Success: "green",
  Pending: "orange",
  Bounced: "red",
  Running: "blue",
  Paused: "yellow",
  location: "Newyork, USA",
  avatar: companyicon,
};

const LeadManagementTable = ({ data }) => (
  <Table variant="simple" border="1px solid" borderColor="gray.200">
    <Thead>
      <Tr bg="#FAFAFA">
        <Th>
          <Checkbox />
        </Th>
        
        <Th className="table-header-style">Name</Th>
        <Th className="table-header-style">Phone</Th>
        <Th className="table-header-style">Email</Th>
        <Th className="table-header-style">Location</Th>
        <Th className="table-header-style">Created</Th>
        <Th className="table-header-style">Last Activity</Th>
        <Th className="table-header-style">Status</Th>
        <Th className="table-header-style">Action</Th>
      </Tr>
    </Thead>
    <Tbody>
      {data.map((item) => (
        <Tr key={item.id}>
          <Td>
            <Checkbox />
          </Td>
          <Flex align="center">
            <Avatar src={item.avatar} size="sm" mr="2" />
            <Box m="2" mt="2">
              <Text fontWeight="medium" fontSize="sm" mb="0.5">
                {item.name}
              </Text>
              <Text fontSize="xs" color="gray.500" lineHeight="2">
                {item.role}
              </Text>
            </Box>
          </Flex>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {item.phone1}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {item.email}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {item.location}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
            {moment(item.createdAt).format('DD-MM-YYYY')}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {item.lastActivated}
            </Td>
          <Td>
            <Badge
              bg={item.status==="Inactive" ?"red":"green"}
              borderRadius="md"
              p="2"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="auto"
              fontWeight="600"
              color="white"
              fontFamily="Poppins"
            >
              {item.status}
            </Badge>
          </Td>
        
          <Td>
            <IconButton
              icon={<FaEllipsisV />}
              variant="ghost"
              aria-label="Options"
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

const UserManagements = () => {
  const [filter, setFilter] = useState("Active Campaign");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userData, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await CallAPI(endpoints.getUsers); // Replace with your actual endpoint
      if (res?.code === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const filteredData = userData.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <Box p={2} overflowX="auto">
      <AddNewUser   isOpen={isOpen} onClose={onClose} getData={getData}/>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex
          align="center"
          justifyContent="space-between"
          borderRadius="md"
          width="100%"
         p="3"
          gap={10}
          direction={{ base: "column", md: "row" }}
        >
          <CompanyProfile />
        </Flex>
      </Box>
      <Box p={4} m={2} borderWidth="1px" borderRadius="md" bg="white">
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Input placeholder="Search User" width="300px" />
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                colorScheme="white"
                border="1px solid #E8E8E8"
                color="#262A2A"
                rightIcon={<ChevronDownIcon />}
                mr={2}
                fontSize={"14px"}
                fontWeight={"400"}
              >
                Export
              </MenuButton>
              <MenuList>
                <MenuItem>Export as CSV</MenuItem>
                <MenuItem>Export as PDF</MenuItem>
                <MenuItem>Export as Excel</MenuItem>
              </MenuList>
            </Menu>
            <Button colorScheme="red" onClick={onOpen}>
              Add User
            </Button>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={4}>
          <HStack>
            <Select
              fontSize={"14px"}
              fontWeight={"400"}
              placeholder="Sort by"
              width="150px"
            >
              <option value="name">Name</option>
              <option value="date">Date</option>
            </Select>
            <Box border="1px solid #E8E8E8" padding="6px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </HStack>
          <HStack>
            <Button color="blue" fontSize={"14px"} fontWeight={"600"}>
              Manage Columns
            </Button>
            <Button
              colorScheme="white"
              border="1px solid #E8E8E8"
              color="#262A2A"
              leftIcon={<FiFilter />}
            >
              Filter
            </Button>
          </HStack>
        </Box>
        <LeadManagementTable data={paginatedData} />
        <HStack justifyContent="space-between" mt={4} alignItems="center">
          <HStack>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>
          <HStack>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1}
              leftIcon={<ChevronLeftIcon />}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === totalPages}
              rightIcon={<ChevronRightIcon />}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
};

export default UserManagements;