import React, { useState } from 'react';
import { Box, Flex, Image, Text} from '@chakra-ui/react';
import activecampaigns from "./icons/activecampaign.png"
import opencampaigns from "./icons/opencampaigns.png"
import sentcampaigns from "./icons/sentcampaign.png"
import compleatecampaigns from "./icons/copleatedcampaigns.png"

import  './Campaign.module.css';

const CampaignCards = () => {

    const [campaigns, setCampaigns] = useState({
        created: 200,
        running: 92,
        ended: 88,
        draft: 20,
      });
return (
    <>
<Box width={"100%"} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} mt="10px" pl="4" pr="4"
   >
{/* ************************************Box 1**************************************** */}
<Box 
  width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} 
  mt="5px"  
  boxShadow="1px 2px 10px lightgray" 
  borderRadius="15px" 
  bg="#F6DE23" 

  p="20px"
>
  <Flex 
    className="textBottomMargin" 
    alignItems="center" 
    justifyContent="space-between"
  >
    <Image 
      src={activecampaigns} 
      w="50px" 
      h="50px" 
      bg="white" 
      p="10px" 
      borderRadius="15px" 
    />
    <Box ml="10px">
      <Text fontWeight="500" fontSize="16px"   color={'white'} lineHeight="1">Active Campaigns</Text>
      <Text fontSize="25px"  color={'white'} lineHeight="1" fontWeight="600" >
        {campaigns.created}
      </Text>
    </Box>
  </Flex>
</Box>

<Box 
  width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} 
  mt="5px"  
  boxShadow="1px 2px 10px lightgray" 
  borderRadius="15px" 
  bg="#4379EE" 

  p="20px"
>
  <Flex 
    className="textBottomMargin" 
    alignItems="center" 
    justifyContent="space-between"
  >
    <Image 
      src={sentcampaigns} 
      w="50px" 
      h="50px" 
      bg="white" 
      p="10px" 
      borderRadius="15px" 
    />
    <Box ml="10px">
      <Text fontWeight="500" fontSize="16px"  color={'white'} lineHeight="1">Sent Campaigns</Text>
      <Text fontSize="25px" lineHeight="1"   color={'white'} fontWeight="700" >
        {campaigns.created}
      </Text>
    </Box>
  </Flex>
</Box>
<Box 
  width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} 
  mt="5px"  
  boxShadow="1px 2px 10px lightgray" 
  borderRadius="15px" 
  bg="#04EBCF" 

  p="20px"
>
  <Flex 
    className="textBottomMargin" 
    alignItems="center" 
    justifyContent="space-between"
  >
    <Image 
      src={opencampaigns} 
      w="50px" 
      h="50px" 
      bg="white" 
      p="10px" 
      borderRadius="15px" 
    />
    <Box ml="10px">
      <Text fontWeight="500"  color={'white'} fontSize="16px" lineHeight="1">Open Campaigns</Text>
      <Text fontSize="25px" color={'white'} lineHeight="1" fontWeight="700" >
        {campaigns.created}
      </Text>
    </Box>
  </Flex>
</Box>
<Box 
  width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} 
  mt="5px"  
  boxShadow="1px 2px 10px lightgray" 
  borderRadius="15px" 
  bg="#C129F4" 

  p="20px"
>
  <Flex 
    className="textBottomMargin" 
    alignItems="center" 
    justifyContent="space-between"
  >
    <Image 
      src={compleatecampaigns} 
      w="50px" 
      h="50px" 
      bg="white" 
      p="10px" 
      borderRadius="15px" 
    />
    <Box ml="10px">
      <Text fontWeight="500"   color={'white'} fontSize="16px" lineHeight="1">Compleated Campaigns</Text>
      <Text fontSize="25px"  color={'white'} lineHeight="1" fontWeight="700" >
        {campaigns.created}
      </Text>
    </Box>
  </Flex>
</Box>
{/* ************************************Box 2**************************************** */}

</Box>
</>
)
}


export default CampaignCards;