import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

const Layout = ({ children }) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const onToggle = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="80vh">
      {/* Header */}
      <Box
        as="header"
        position="fixed"
        top="0"
        left="0"
        right="0"
        height="60px"
        zIndex="1000"
        boxShadow="sm"
        backgroundColor="white"
      >
        <Header />
      </Box>

      {/* Sidebar and Main Content */}
      <Box display="flex" flex="1" pt="70px" pb="60px">
        {/* Sidebar */}
        <Box
          as="aside"
          position="fixed"
          top="60px"
          bottom="60px"
          left="0"
          boxShadow="md"
          backgroundColor="none"
          padding="4"
          paddingTop="0px"
          overflow="hidden"
          transition="width 0.3s ease"
        >
          <Sidebar isCollapsed={isSidebarCollapsed} onToggle={onToggle}  />
        </Box>

        {/* Main Content */}
        <Box
          as="main"
          flex="1"
          ml={{ base: isSidebarCollapsed ? "1%" : "1%", md: isSidebarCollapsed ? "4%" : "10%" }}
          padding="10"
          paddingTop="5"
          backgroundColor="#F9F9FC"
          overflowY="auto"
          transition="margin-left 0.3s ease"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
