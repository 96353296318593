const endpoints = {
  signup: '/api/user/register',
  signin: '/api/user/login',
  googleSignIn: '/api/auth/google-signin',
  emailverify: '/api/user/emailverify',
  sendotp: '/api/user/send-otp',
  resetpassword: '/api/user/change-password',
  linkedinCampaign: '/api/user/linkedin-campaign',
  emailCampaign: '/api/user/email-campaign',
  whatsapp_campaign: '/api/user/whatsapp-campaign',
  allCampaigns: '/api/user/all-campaigns',
  twitterCampaign: '/api/user/twitter-campaign',
  instagram_campaign: '/api/user/instagram-campaign',
  facebook_campaign: '/api/user/facebook-campaign',
  updateUser: '/api/user/update-user-profile',
  newsletterSubscriber: '/api/user/newsletter-subscribers',
  findUser: '/api/user/find-user',
  createLead:"/api/user/create-lead",
  getleads: "/api/user/get-leads",
  createLeads:"/api/user/create-leads",

  getEvents:"/api/user/get-events",
  createEvents:"/api/user/create-events",

  createCampaigns:"/api/user/create-campaigns",
  getCampaigns:"/api/user/get-campaigns",



  getUsers:"/api/user/get-users",
  createUsers:"/api/user/create-users",

  setAlphaTesting:'/api/user/set-alpha-testing-type',
  setBetaTesting:'/api/user/set-beta-testing-type',
  campaignDetails:'/api/user/campaign-detail',
  findLeads:'/api/user/find-leads',
  addcard:'api/user/add-card',
  getCardData:'api/user/get-card',
  DeleteCard:'api/user/delete-card',
  updateCard:'api/user/update-card'
};

export default endpoints;
