import { Box, Flex, Text, Progress, Icon, Image, Select } from '@chakra-ui/react';
import { FaWhatsapp, FaFacebook, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import dotssymbol from "./icons/dotssymbol.png";
import linkedinLogo from "./icons/linkedinlogo.png"
import twitterLogo from "./icons/twitterlogo.png"
import watsapLogo from "./icons/watsaplogo.png"
import facebookLogo from "./icons/facebooklogo.png"
import emailLogo from "./icons/emaillogo.png"


// Platforms data with engagement, conversion, and growth metrics
const platforms = [
  { icon: watsapLogo, engagement: 70, conversion: 30, growth: 15 },
  { icon: facebookLogo, engagement: 50, conversion: 40, growth: 10 },
  { icon: linkedinLogo, engagement: 65, conversion: 25, growth: 20 },
  { icon: twitterLogo, engagement: 55, conversion: 35, growth: 10 },
  { icon: emailLogo, engagement: 80, conversion: 20, growth: 5 },
];

// Component for each platform's performance bars
const PerformanceBar = ({ icon, engagement, conversion, growth }) => (
  <Flex align="center" mb={4}>
    {/* Platform Icon */}
    <Image src={icon} alt={icon} boxSize={6} color="gray.600" mr={4} />
    <Box flex="1">
      {/* Individual Progress Bars for Engagement, Conversion, and Growth */}
      <Progress
          colorScheme="blue"
          value={engagement}
          flex="1"
          height="10px"
          mr="2px"
          mt="8px"
          borderRadius="4px"
          label={`${engagement}%`}
          sx={{
            "& > div": { // styling the label inside the Progress component
              color: "white",
              fontWeight: "bold",
              fontSize: "sm",
            },
          }}
        />
        <Progress
          colorScheme="yellow"
          value={conversion}
          flex="1"
          height="10px"
          mr="2px"
          mt="8px"
          borderRadius="4px"
          label={`${conversion}%`}
          sx={{
            "& > div": {
              color: "black",
              fontWeight: "bold",
              fontSize: "sm",
            },
          }}
        />
        <Progress
          colorScheme="red"
          value={growth}
          flex="1"
          height="10px"
          mt="8px"
          borderRadius="4px"
          label={`${growth}%`}
          sx={{
            "& > div": {
              color: "white",
              fontWeight: "bold",
              fontSize: "sm",
            },
          }}
        />
    </Box>
  </Flex>
);

// Main component for top performing platforms section
const TopPerformingPlatforms = () => (
  <Box>
    {/* Header with dots symbol, title, and select dropdown */}
    <Flex align="center" justify="space-between" mb={4}>
      <Flex align="center">
        <Image src={dotssymbol} alt="dotssymbol" mr="2" boxSize="10px" />
        <Text as="h2" fontFamily="Poppins, sans-serif" fontWeight="500" fontSize="18px" lineHeight="27px" m="0">
          Top Performing Platforms
        </Text>
      </Flex>
      <Select maxW="120px" size="sm" defaultValue="Last 30 Days">
        <option value="Last 30 Days">Last 30 Days</option>
        <option value="Last 7 Days">Last 7 Days</option>
        <option value="Last Year">Last Year</option>
      </Select>
    </Flex>

    {/* Main content area with PerformanceBars and color legend */}
    <Flex>
      {/* Render PerformanceBar for each platform */}
      <Box flex="1">
        {platforms.map((platform, index) => (
          <PerformanceBar key={index} {...platform} />
        ))}
      </Box>

      {/* Color Legend */}
      <Box ml={4}>
        <Flex align="center" mb={2} >
          <Box boxSize="10px" bg="blue.500" borderRadius="full" mr="2"/>
          <Text fontSize="sm" lineHeight="1"  mt="15px">Engagement</Text>
        </Flex>
        <Flex align="center" mb={2}>
          <Box boxSize="10px" bg="yellow.500" borderRadius="full" mr="2" />
          <Text fontSize="sm" lineHeight="1" mt="15px">Conversion</Text>
        </Flex>
        <Flex align="center">
          <Box boxSize="10px" bg="red.500" borderRadius="full" mr="2" />
          <Text fontSize="sm" lineHeight="1" mt="15px">Growth</Text>
        </Flex>
      </Box>
    </Flex>
  </Box>
);

export default TopPerformingPlatforms;
