import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";

const CompanyReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const barData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Leads",
        data: [100, 120, 300, 200, 450, 320, 230, 340, 180, 250, 300, 500],
        backgroundColor: "#00B5D8",
      },
    ],
  };

  const doughnutData = {
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    datasets: [
      {
        data: [44, 58, 41, 17],
        backgroundColor: ["#4299E1", "#48BB78", "#ED8936", "#D53F8C"],
      },
    ],
  };

  const leadsData = [
    {
      leadName: "Collins",
      companyName: "NovaWave LLC",
      phone: "+1 8753544533",
      email: "robertson@example.com",
      leadStatus: "Closed",
      leadOwner: "Hendry",
      source: "Paid Social",
      createdDate: "25 Sep 2023, 01:22 pm",
    },
    // Add more data rows here...
  ];

  return (
    <Box
      p={4}
      mt={4}
      borderWidth="1px"
      borderRadius="md"
      bg="white"
      overflowX="auto"
    >
      {/* Search and Download Section */}
      <HStack
        justifyContent="space-between"
        mb="4"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Input
          placeholder="Search Leads"
          w={{ base: "100%", md: "250px" }}
          mb={{ base: 4, md: 0 }}
        />
        <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
          Download Report
        </Button>
      </HStack>

      {/* Chart Section */}
      <HStack spacing="6" mb="6" flexDirection={{ base: "column", md: "row" }}>
        <Box
          width={{ base: "100%", md: "48%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
          mb={{ base: 4, md: 0 }}
        >
          {/* <Flex justify="space-between" align="center"> */}
          <Flex
            justify="space-between"
            flexDirection={{base:"column",md:"row"}}
            gap={"6px"}
            align="center"
            mb="2"
          >
            <Text
              as="h2"
              fontWeight="500"
              fontSize={{ base: "16px", md: "18px" }}
            >
              Contacts By Year
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <ReactApexChart
            options={{
              chart: {
                id: "basic-line-chart",
              },
              xaxis: {
                categories: barData.labels,
              },
              yaxis: {
                title: {
                  text: "Values",
                },
              },
              stroke: {
                curve: "straight",
              },
              colors: ["#FFA500"],
            }}
            series={barData.datasets}
            type="line"
            height={350}
          />
        </Box>

        <Box
          width={{ base: "100%", md: "48%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
        >
          <Flex justify="space-between" align="center">
            <Text
              as="h2"
              fontWeight="500"
              fontSize={{ base: "16px", md: "18px" }}
            >
              Contacts By Source
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <Doughnut data={doughnutData} height={350} />
        </Box>
      </HStack>

      {/* Table Section */}
      <HStack
        justifyContent="space-between"
        mb="4"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Select
          placeholder="Sort By"
          w={{ base: "100%", md: "200px" }}
          mb={{ base: 4, md: 0 }}
        >
          <option value="name">Lead Name</option>
          <option value="company">Company</option>
          <option value="status">Lead Status</option>
        </Select>
        <HStack>
          <IconButton
            icon={<AiOutlineColumnHeight />}
            aria-label="Manage Columns"
          />
          <IconButton icon={<AiOutlineFilter />} aria-label="Filter" />
        </HStack>
      </HStack>

      {/* Leads Table */}
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Lead Name</Th>
              <Th>Company Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Lead Status</Th>
              <Th>Lead Owner</Th>
              <Th>Source</Th>
              <Th>Created Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leadsData.map((lead, index) => (
              <Tr key={index}>
                <Td>{lead.leadName}</Td>
                <Td>{lead.companyName}</Td>
                <Td>{lead.phone}</Td>
                <Td>{lead.email}</Td>
                <Td>
                  <Tag colorScheme={getTagColor(lead.leadStatus)}>
                    {lead.leadStatus}
                  </Tag>
                </Td>
                <Td>{lead.leadOwner}</Td>
                <Td>{lead.source}</Td>
                <Td>{lead.createdDate}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

// Helper function for Tag color based on status
function getTagColor(status) {
  switch (status) {
    case "Closed":
      return "green";
    case "Not Contacted":
      return "blue";
    case "Lost":
      return "red";
    case "Contacted":
      return "yellow";
    default:
      return "gray";
  }
}

export default CompanyReport;
