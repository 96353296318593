import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  HStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";

const TaskReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const barData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Leads",
        data: [100, 120, 300, 200, 450, 320, 230, 340, 180, 250, 300, 500],
        backgroundColor: "#00B5D8",
      },
    ],
  };

  const options = {
    chart: {
      id: "basic-line-chart",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    stroke: {
      curve: "stepline",
    },
    colors: ["#FF4500"],
  };

  const doughnutData = {
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    datasets: [
      {
        data: [44, 58, 41, 17],
        backgroundColor: ["#4299E1", "#48BB78", "#ED8936", "#D53F8C"],
      },
    ],
  };

  // Mock data for table rows
  const leadsData = [
    {
      leadName: "Collins",
      companyName: "NovaWave LLC",
      phone: "+1 8753544533",
      email: "robertson@example.com",
      leadStatus: "Closed",
      leadOwner: "Hendry",
      source: "Paid Social",
      createdDate: "25 Sep 2023, 01:22 pm",
    },
  ];

  return (
    <Box p={{ base: "4", md: "5" }}>
      {/* Search and Download Section */}
      <HStack
        justifyContent="space-between"
        mb="4"
        flexDirection={{ base: "column", sm: "row" }}
        spacing={{ base: 4, sm: 6 }}
      >
        <Input placeholder="Search Leads" w={{ base: "100%", sm: "250px" }} />
        <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
          Download Report
        </Button>
      </HStack>

      {/* Charts Section */}
      <HStack
        spacing={{ base: "4", sm: "6" }}
        mb="6"
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Task by Year Chart */}
        <Box
          width={{ base: "100%", md: "48%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
          mb={{ base: "4", md: "0" }}
        >
          <Flex justify="space-between" align="center">
            <Text
              as="h2"
              fontFamily="Poppins, sans-serif"
              fontWeight="500"
              fontSize={{ base: "16px", sm: "18px" }}
              lineHeight="27px"
            >
              Task by Year
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <ReactApexChart
            options={options}
            series={barData.datasets}
            type="line"
            height={350}
          />
        </Box>

        {/* Task by Types Chart */}
        <Box
          width={{ base: "100%", md: "48%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
        >
          <Flex justify="space-between" align="center">
            <Text
              as="h2"
              fontFamily="Poppins, sans-serif"
              fontWeight="500"
              fontSize={{ base: "16px", sm: "18px" }}
              lineHeight="27px"
            >
              Task by Types
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <Doughnut data={doughnutData} />
        </Box>
      </HStack>

      {/* Table Section */}
      <HStack
        justifyContent="space-between"
        mb="4"
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Select placeholder="Sort By" w={{ base: "100%", sm: "200px" }}>
          <option value="name">Lead Name</option>
          <option value="company">Company</option>
          <option value="status">Lead Status</option>
        </Select>

        <HStack spacing={4}>
          <IconButton
            icon={<AiOutlineColumnHeight />}
            aria-label="Manage Columns"
          />
          <IconButton icon={<AiOutlineFilter />} aria-label="Filter" />
        </HStack>
      </HStack>

      {/* Leads Table */}
      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Lead Name</Th>
              <Th>Company Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Lead Status</Th>
              <Th>Lead Owner</Th>
              <Th>Source</Th>
              <Th>Created Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leadsData.map((lead, index) => (
              <Tr key={index}>
                <Td>{lead.leadName}</Td>
                <Td>{lead.companyName}</Td>
                <Td>{lead.phone}</Td>
                <Td>{lead.email}</Td>
                <Td>
                  <Tag colorScheme={getTagColor(lead.leadStatus)}>
                    {lead.leadStatus}
                  </Tag>
                </Td>
                <Td>{lead.leadOwner}</Td>
                <Td>{lead.source}</Td>
                <Td>{lead.createdDate}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

// Helper function for Tag color based on status
function getTagColor(status) {
  switch (status) {
    case "Closed":
      return "green";
    case "Not Contacted":
      return "blue";
    case "Lost":
      return "red";
    case "Contacted":
      return "yellow";
    default:
      return "gray";
  }
}

export default TaskReport;
