import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CallAPI } from '../../../Services/service';
const useGetConversations = () => {
  const BASE_URL = 'http://localhost:5223';
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    console.log('BASE_URL:', BASE_URL); // Debugging line to check BASE_URL
    if (!BASE_URL) {
      toast.error('API base URL is not defined');
      return;
    }

    const getConversations = async () => {
      setLoading(true);
      try {
        const data = JSON.parse(localStorage.getItem('userData'));
        const SenderId = data?.id;
        console.log(BASE_URL);
        // const res = await fetch(`${BASE_URL}/api/messages/sidebar`, {
        //   method: 'GET',
        //   headers: { 'Content-Type': 'application/json' },
        //   credentials: 'include',
        // });
        const res = await CallAPI(`${BASE_URL}/api/sidebar`, { id: SenderId });
        console.log('res is', res);

        if (!res) {
          throw new Error('error in fetching data');
        }
        setConversations(res);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getConversations();
  }, []);

  return { loading, conversations };
};
export { useGetConversations };
