import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Badge,
  AvatarGroup,
  Avatar,
  Input,
  Select,
  useDisclosure,
  Flex,
  useBreakpointValue,
  Icon,
Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker"; // Make sure to install react-datepicker and react-datepicker-css for date picker functionality
import "react-datepicker/dist/react-datepicker.css";
import { FiFilter } from "react-icons/fi";
import  AddCampaignDrawer from "./AddCampaignDrawer";
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";

const campaignData = [
  {
    id: 1,
    name: "Distribution",
    type: "Public Relations",
    progress: {
      opened: 40.5,
      closed: 20.5,
      unsubscribe: 30.5,
      delivered: 70.5,
      conversation: 35.0,
    },
    members: ["https://bit.ly/dan-abramov", "https://bit.ly/kent-c-dodds"],
    startDate: "25 Sep 2023",
    endDate: "29 Sep 2023",
    status: "Success",
    created: "25 Sep 2023",
    statusColor:"green"
  },
  {
    id: 2,
    name: "Merchandising",
    type: "Content Marketing",
    progress: {
      opened: 65.5,
      closed: 83.5,
      unsubscribe: 67.5,
      delivered: 32.0,
      conversation: 22.5,
    },
    members: ["https://bit.ly/sage-adebayo", "https://bit.ly/code-beast"],
    startDate: "03 Oct 2023",
    endDate: "16 Oct 2023",
    status: "Pending",
    created: "03 Oct 2023",
    statusColor:"orange"
  },
  {
    id: 3,
    name: "Merchandising",
    type: "Content Marketing",
    progress: {
      opened: 65.5,
      closed: 83.5,
      unsubscribe: 67.5,
      delivered: 32.0,
      conversation: 22.5,
    },
    members: ["https://bit.ly/sage-adebayo", "https://bit.ly/code-beast"],
    startDate: "03 Oct 2023",
    endDate: "16 Oct 2023",
    status: "Paused",
    created: "03 Oct 2023",
    statusColor:"orange"
  },
  // ... Add more campaigns as needed
];

const statusColors = {
  Success: "green",
  Pending: "orange",
  Bounced: "red",
  Running: "blue",
  Paused: "yellow",
};



const CampaignTable = ({ data }) => (
  <Table variant="simple" border="1px solid" size="sm" borderColor="gray.200">

    <Thead>
      <Tr bg="#FAFAFA">
        <Th>
          <Checkbox />
        </Th>
        <Th className='table-header-style' >Name</Th>
        <Th className='table-header-style'>Type</Th>
        <Th className='table-header-style'>Progress</Th>
        <Th className='table-header-style'>Members</Th>
        <Th className='table-header-style'>Start Date</Th>
        <Th className='table-header-style'>End Date</Th>
        <Th className='table-header-style'>Status</Th>
        <Th className='table-header-style'>Created</Th>
        <Th className='table-header-style'>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
      {data.map((campaign) => (
        <Tr key={campaign.id}>
          <Td>
            <Checkbox />
          </Td>
          <Td fontSize="16px" fontWeight="400" color="#6F6F6F">{campaign.name}</Td>
          <Td fontSize="16px" fontWeight="400" color="#6F6F6F">{campaign.type}</Td>
          <Td textAlign={"center"}>
  <Box
    display="flex"
    justifyContent="space-between"
    mt="2"
    width="100%"
    textAlign="center"
    flexWrap="wrap" // Add flexWrap="wrap" here
  >
    {Object?.entries(campaign?.progress).map(([label, value]) => (
      <Box
        key={label}
        textAlign="center"
        mx="1"
        width={{ base: "33%", md: "30%" }} // Adjust width for responsiveness
        mb="2" // Add margin-bottom for spacing between rows
      >
        <Text fontSize="lg" color="#26262A">{`${value}%`}</Text>
        <Text fontSize="sm" color="#6F6F6F">{label}</Text>
      </Box>
    ))}
  </Box>
</Td>

          <Td>
            <AvatarGroup size="sm" max={3}>
              {campaign.members.map((member, index) => (
                <Avatar key={index} src={member} />
              ))}
            </AvatarGroup>
          </Td>
          <Td fontSize="16px" fontWeight="400" color="#6F6F6F" >{campaign.startDate}</Td>
          <Td fontSize="16px" fontWeight="400" color="#6F6F6F">{campaign.endDate}</Td>
          <Td>
          <Badge
  bg={campaign.statusColor}
  borderRadius="md"
  p="2"
  display="flex"
  alignItems="center"
  justifyContent="center"
  textAlign="center"
  width="auto" // Adjust width based on content size
  fontWeight="600"
  color="white"
  fontFamily="Poppins"
>
  {campaign.status}
</Badge>
           
          </Td>
          <Td fontSize="16px" fontWeight="400" color="#6F6F6F">{campaign.created}</Td>
          <Td>
            <IconButton
              icon={<FaEllipsisV />}
              variant="ghost"
              aria-label="Options"
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

const CampaignTabs = () => {
  const [filter, setFilter] = useState("Active Campaign");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
 const [campaignData1,setData] = useState([])

 useEffect(() => {
  getData();
}, []);

const getData = async () => {
  try {
    const res = await CallAPI(endpoints.getCampaigns); // Replace with your actual endpoint
    if (res?.code === 200) {
      setData(res.data);
    }
  } catch (error) {
    console.error(error);
  }
};

  const filteredData = campaignData.filter((campaign) => {
    switch (filter) {
      case "Active Campaign":
        return campaign.status === "Active" || campaign.status === "Pending";
      case "Completed Campaign":
        return campaign.status === "Success";
      case "Archived Campaign":
        return campaign.status === "Paused" || campaign.status === "Bounced";
      default:
        return true;
    }
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };
  const tabListDirection = useBreakpointValue({ base: "column", md: "row" });


  return (
    <Box p={5} overflowX="auto" >
      <AddCampaignDrawer isOpen={isOpen} onClose={onClose} getData={getData} />
      <Tabs variant="enclosed" >
      <Box p={4} borderWidth="1px" borderRadius="md" bg="white" >
        <TabList display="flex" justifyContent="space-between" borderBottom={"none"}  flexDirection={tabListDirection} 
          width={tabListDirection === "column" ? "100%" : "50%"}>
          <Tab
            onClick={() => setFilter("Active Campaign")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Active Campaign
          </Tab>
          <Tab
            onClick={() => setFilter("Completed Campaign")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Completed Campaign
          </Tab>
          <Tab
            onClick={() => setFilter("Archived Campaign")}
            _selected={{
              borderBottom: "3px solid red", // Red bottom border for selected tab
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Archived Campaign
          </Tab>
        </TabList>
        </Box>

        <Box p={4} mt={4} borderWidth="1px" borderRadius="md" bg="white" overflowX="auto">
       
  <Box 
    display="flex" 
    flexDirection={{ base: "column", md: "row" }}  // Stack vertically on small screens
    justifyContent="space-between" 
    mb={4} 
    alignItems="center"
  >
    <Input 
      placeholder="Search Campaign" 
      width={{ base: "100%", md: "300px" }}  // Full width on small screens
      mb={{ base: 4, md: 0 }}  // Margin bottom for spacing on smaller screens
    />
    <Box display="flex" flexDirection={{ base: "column", md: "row" }} alignItems="center">
      <Menu>
        <MenuButton
          as={Button}
          colorScheme="white"
          border="1px solid #E8E8E8"
          color="#262A2A"
          rightIcon={<ChevronDownIcon />}
          mr={{ base: 0, md: 2 }}  // Margin right on medium and larger screens
          mb={{ base: 4, md: 0 }}  // Margin bottom for spacing on smaller screens
          fontSize="14px"
          fontWeight="400"
          width={{ base: "100%", md: "auto" }}  // Full width on small screens
        >
          Export
        </MenuButton>
        <MenuList>
          <MenuItem>Export as CSV</MenuItem>
          <MenuItem>Export as PDF</MenuItem>
          <MenuItem>Export as Excel</MenuItem>
        </MenuList>
      </Menu>
      <Button 
        colorScheme="red" 
        onClick={onOpen} 
        width={{ base: "100%", md: "auto" }}  // Full width on small screens
      >
        Add New Campaign
      </Button>
    </Box>
</Box>


<Box display="flex" flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" mb={4}>
  <HStack spacing={4} mb={{ base: 4, md: 0 }} width={{ base: "100%", md: "auto" }}>
    <Select
      fontSize="14px"
      fontWeight="400"
      placeholder="Sort by"
      width={{ base: "100%", md: "150px" }}  // Full width on small screens
    >
      <option value="name">Name</option>
      <option value="date">Date</option>
    </Select>
    <Box 
      border="1px solid #E8E8E8" 
      padding="6px" 
      width={{ base: "100%", md: "auto" }}  // Full width on small screens
    >
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        placeholderText="Start Date"
        style={{ width: "100%" }}  // Ensure DatePicker also takes full width
      />
    </Box>
  </HStack>
  
  <HStack spacing={4} width={{ base: "100%", md: "auto" }}>
    <Button 
      color="blue" 
      fontSize="14px" 
      fontWeight="600" 
      width={{ base: "100%", md: "auto" }}  // Full width on small screens
    >
      Manage Columns
    </Button>
    <Button
      colorScheme="white"
      border="1px solid #E8E8E8"
      color="#262A2A"
      leftIcon={<Icon as={FiFilter} />}
      width={{ base: "100%", md: "auto" }}  // Full width on small screens
    >
      Filter
    </Button>
  </HStack>
</Box>

          <CampaignTable data={filteredData} />
        {/* Pagination Controls */}
        <HStack justifyContent="space-between" mt={4} alignItems="center">
          {/* Dropdown for selecting items per page */}
          <HStack>
            <Box  color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box  color="#6F6F6F">entries</Box>
          </HStack>

          {/* Pagination Buttons */}
          <HStack>
            <Button
              colorScheme="white"
              color="#6F6F6F"              onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1}
              leftIcon={<ChevronLeftIcon />}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"              onClick={() => handlePageChange(1)}
              disabled={currentPage === totalPages}
              rightIcon={<ChevronRightIcon />}
            >
              Next
            </Button>
          </HStack>
        </HStack>
        </Box>

      </Tabs>
   
    </Box>
  );
};

export default CampaignTabs;
