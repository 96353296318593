import React, { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Select } from "@chakra-ui/react";
import  { useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Image,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  InputRightElement,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  InputGroup,
  Flex,
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import {} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { GoScreenFull } from "react-icons/go";
import { FaPlus } from "react-icons/fa";
import { RiExportFill } from "react-icons/ri";
import { IoFilter } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { FaLink } from "react-icons/fa6";
import { CgBox } from "react-icons/cg";
import endpoint from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaCalculator } from "react-icons/fa";
import { FaEllipsisV } from "react-icons/fa";
import { RiPriceTag2Line } from "react-icons/ri";
import endpoints from "../../Services/endpoint";
const dummyLeads = [
  {
    id: 1,
    name: "INV001",
    phone: "Individual",
    email: "Paid",
    status: "Delivered",
    placement: "WhatsApp",
    method: "Credit Card",
    amount: "$250.00",
    priority: "Medium",
  },
  {
    id: 2,
    name: "INV002",
    phone: "Company",
    email: "Unpaid",
    status: "Pending",
    placement: "Email",
    method: "PayPal",
    amount: "$300.00",
    priority: "High",
  },
  {
    id: 3,
    name: "INV003",
    phone: "Individual",
    email: "Paid",
    status: "Shipped",
    placement: "Facebook",
    method: "Debit Card",
    amount: "$150.00",
    priority: "Low",
  },
  {
    id: 4,
    name: "INV004",
    phone: "Individual",
    email: "Paid",
    status: "Delivered",
    placement: "Instagram",
    method: "Bank Transfer",
    amount: "$400.00",
    priority: "High",
  },
];

export default function Billing() {
  const [selectedItems, setSelectedItems] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [leads, setLeads] = useState(dummyLeads);
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState(null);
  const [newLink, setNewLink] = useState("");
  const [createLead, setCreateLead] = useState({
    name: '',
    card_number: '',
    exp_date: '',
    cvv: ''
  });

  const [formErrors, setFormErrors] = useState({});

  const [id,setId]=useState(null)
 
  const handleinpuchange = (e) => {
    setCreateLead({ ...createLead, [e.target.name]: e.target.value });
  };

  const [filters, setFilters] = useState({
    name: "",
    card_number: "",
    exp_date: "",
    cvv: "",
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newLead, setNewLead] = useState({
    name: "",
    card_number: "",
    exp_date: "",
    cvv: ""
  });

  const toast = useToast();
 
  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!newLead.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!newLead.card_number) {
      errors.card_number = "card number is required";
      isValid = false;
    } 
    if (!newLead.exp_date) {
      errors.exp_date = "exp date is required";
      isValid = false;
    } 
    if (!newLead.cvv) {
      errors.cvv = "cvv is is required";
      isValid = false;
    } 


    setFormErrors(errors);
    return isValid;
  };

  const handleCheckboxChange = (id) => {
    const updatedLeads = leads.map((lead) =>
      lead.id === id ? { ...lead, isSelected: !lead.isSelected } : lead
    );
    setLeads(updatedLeads);
    setSelectedItems(updatedLeads.filter((lead) => lead.isSelected).length);
  };

 

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLeads = leads.filter((lead) => {
    return (
      (!searchTerm ||
        lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (!filters.name ||
        lead.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.phone || lead.phone.includes(filters.phone)) &&
      (!filters.email ||
        lead.email.toLowerCase().includes(filters.email.toLowerCase())) &&
      (!filters.date || lead.date === filters.date) &&
      (!filters.priority || lead.priority === filters.priority)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    navigate("/AllCampaigns");
  };

 const handleAddLead = () => {
    setNewLead({
      name: "",
      card_number: "",
      exp_date: "",
      cvv: "",
    });
    setIsEditing(false);
    setShowAddForm(true);
  };
 
  const handleEditLead = (card) => {
    console.log("card data is: ", card);
    setId(card.id)
    setCreateLead(card); 
    setIsEditing(true); 
    setShowAddForm(true); 
  };
  
  const [cardss, setCardss] = useState([]);

  const handleDeleteLead = async (id) => {
    console.log("id for delete", id);
    
    console.log("Deleting card with ID:", id); // Log the ID
  
    try {
      const api= `${endpoint.DeleteCard}/${id}`
      console.log("api path", api);
      const res = await CallAPI(api)
      console.log("res of delete is ", res);
      setCards(prevCards => prevCards.filter(card => card.id !== id));

      toast({
        title: "Card removed successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,

      });
    } catch (error) {
      console.error("Error removing card:", error); // Log the error
      toast({
        title: "Error removing card.",
        description: "Something went wrong while deleting the card.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  const handleDeleteAllLeads = () => {
    onClose();
    if (
      window.confirm("Are you sure you want to delete all leads permanently?")
    ) {
      setLeads([]);
      setSelectedItems(0);
      toast({
        title: "All leads deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };



  const handleSelectAll = () => {
    const allSelected = selectedItems === leads.length;
    const updatedLeads = leads.map((lead) => ({
      ...lead,
      isSelected: !allSelected,
    }));
    setLeads(updatedLeads);
    setSelectedItems(allSelected ? 0 : updatedLeads.length);
  };

  const handleUnselectAll = () => {
    const updatedLeads = leads.map((lead) => ({ ...lead, isSelected: false }));
    setLeads(updatedLeads);
    setSelectedItems(0);
  };


  const [showCard, setShowCard] = useState(null);

  const toggleCard = (id) => {
    if (showCard === id) {
      setShowCard(null);
    } else {
      setShowCard(id);
    }
  };


  const CreateLead = async () => {
    try {
        let res;
        console.log("Id is : ", id);

        if (isEditing) {
            const api = `${endpoint.updateCard}/${id}`;
            console.log("api is ", api);

            res = await CallAPI(api, createLead); 

            if (res?.code === 200) {
                setCards(prevCards => 
                    prevCards.map(card => 
                        card.id === id ? { ...card, ...createLead } : card
                    )
                );
            }
        } else {
            res = await CallAPI(endpoint.addcard, createLead); 

            if (res?.code === 200) {
                setCards(prevCards => [...prevCards, res.data]); 
            }
        }

        toast({
            title: isEditing ? "Card updated successfully." : "Card added successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });

    } catch (error) {
        console.error("Error>>", error);
        toast({
            title: `An error occurred while ${isEditing ? "updating" : "adding"} the card: ${error.message}`,
            status: "error",
            duration: 3000,
            isClosable: true,
        });
    } finally {
        setShowAddForm(false); 
        setIsEditing(false); 
        setCreateLead({}); 
    }
};

  
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {  
    const getdata= async()=>{
      try {
        setLoading(true)
     const   response = await CallAPI(endpoints.getCardData); 
     console.log("response data from new method: ", response)
     setCards(response.data); 
     setLoading(false)
      } catch (error) {
        setError(error.message);
           setLoading(false);
      }
    }
   getdata();
  },[]);

  if (loading) return <p>Loading......</p>;
  if (error) return <p>Error: {error}</p>;

  const handleExporthistory = () => {
    const selectedLeads = leads.filter((lead) => lead.isSelected);

    if (selectedLeads.length === 0) {
      toast({
        title: "No  selected for export.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const csvContent =
      "data:text/csv;charset=utf-8," +
      selectedLeads.map((lead) => Object.values(lead).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "selected_leads.csv");
    document.body.appendChild(link);
    link.click();

    toast({
      title: "History exported successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <>
      <Flex height="100%" direction="column" ml={"10px"}>
        <Box
          w={"100%"}
          p={4}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius="10px"
        >
          <Text
            fontSize="24px"
            color={"black"}
            fontWeight="700"
            ml={-4}
            lineHeight="24px"
          >
            Billing
          </Text>
          <Box>
            <Button
              onClick={handleAddLead}
              mr={-15}
              bg={"#D1202A"}
              color="#FFFFFF"
              borderWidth="1px"
              _hover={{
                backgroundColor: "background: #E03741;",
                color: "#FFFFFF",
              }}
            >
              Add New Card
            </Button>
          </Box>
        </Box>

        <Flex
          mb={7}
          direction={{ base: "column", md: "row" }}
          wrap="wrap"
          gap={4}
        >
          <Box
            bgImage="url('/cardbg.svg')"
            bgSize="cover"
            border={"1px red"}
            borderRadius={"10px"}
            bgPosition="center"
            borderTop="#CFCECE 1.5px solid" 
            flex="1"
            p={4}
            height="170px"
          >
       
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                fontWeight="500"
                color={"black"}
                fontSize="14px"
                lineHeight="16.8px"
              >
                Current Subscription
              </Text>
              
              <Box
                backgroundColor="#C3D7EB"
                height="40px"
                width="40px"
                borderRadius="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <RiPriceTag2Line
                  style={{ fontSize: "24px", color: "#4A86C3" }}
                />
              </Box>
            </Flex>
            <Text
              fontWeight="500"
              mt={-2}
              mb={6}
              fontSize="24px"
              lineHeight="29px"
            >
              Pro
            </Text>

            <Button
              mb={-19}
              bg={"#F2EFFE"}
              color="#E03741"
              borderColor="#E03741"
              borderWidth="1px"
              _hover={{
                backgroundColor: " #E03741;",
                color: "#FFFFFF",
              }}
            >
              Upgrade
            </Button>
          </Box>

          <Box
            bgImage="url('/cardbg.svg')"
            bgSize="cover"
            bgPosition="center"
            flex="1"
            borderRadius={"10px"}
            borderTop="#CFCECE 1.5px solid" 
            p={4}
            height="170px"
          >
            <Select
              ml={-3}
              width={"53%"}
              mt={-2.5}
              fontWeight="500"
              border={"none"}
              fontSize="14px"
              lineHeight="16.8px"
            >
              <option value="monthly">Monthly Spend</option>
              <option value="weekly">Weekly Spend</option>
              <option value="yearly">Yearly Spend</option>
            </Select>
            <Text fontWeight="500" fontSize="24px" mt={2} lineHeight="29px">
              $2000
            </Text>

            <Flex alignItems="center" justifyContent="space-between">
              <Button
                mb={5}
                mt={2}
                bg={"#F2EFFE"}
                color="#E03741"
                borderColor="#E03741"
                borderWidth="1px"
                _hover={{
                  backgroundColor: " #E03741;",
                  color: "#FFFFFF",
                }}
              >
                Export
              </Button>
              <Box textAlign="left">
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="12px"
                  mb="2px"
                >
                  Last updated
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  marginBottom="1px"
                  lineHeight="14.4px"
                >
                  20.2.2029
                </Text>
              </Box>
            </Flex>
          </Box>

          <Box
            bgImage="url('/cardbg.svg')"
            bgSize="cover"
            bgPosition="center"
            borderRadius={10}
            flex="1"
            borderTop="#CFCECE 1.5px solid" 
            p={4}
            height="170px"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                fontWeight="500"
                color={"black"}
                fontSize="14px"
                lineHeight="16.8px"
              >
                Campaign Calculator
              </Text>
              <Box
                backgroundColor="#C3D7EB"
                height="40px"
                width="40px"
                borderRadius="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FaCalculator style={{ fontSize: "24px", color: "#4A86C3" }} />
              </Box>
            </Flex>
            <Button
              mr={2}
              bg={"#F2EFFE"}
              color="#E03741"
              borderColor="#E03741"
              mt={14}
              borderWidth="1px"
              _hover={{
                backgroundColor: " #E03741",
                color: "#FFFFFF",
              }}
            >
              Calculate
            </Button>
          </Box>

           <Box
      borderRadius={"10px"}
      bg="gray.100"
      p={4}
      bg={"#FAFAFA"}
      border={"#CFCECE 1px solid"}
      flex="1"
      height="170px"
    >
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          maxHeight: cards.length > 2 ? "135px" : "auto", 
          overflowY: cards.length > 2 ? "auto" : "visible" 
        }}
      >
        {cards.map((card, index) => (
          <li key={card.id}>
            <Flex
              mb={1}
              pl={2}
              pr={1}
              border={"1px solid #CFCECE"}
              borderRadius={"10px"}
              justify="space-between"
              align="center"
            >
              <Flex align="center">
                <Image
                  src="/visa.svg"
                  alt="Visa"
                  borderRadius="5px"
                  marginRight="10px"
                />
                <Box>
                  <Text mb={0} fontWeight="bold">
                    Visa: 2023
                    {/* Visa: {card.name_on_card} */}
                  </Text>
                  <Text fontWeight="bold">Exp date: {card.exp_date}</Text>
                </Box>
              </Flex>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  variant="ghost"
                  bg={"white"}
                />
                <MenuList>
                  <MenuItem onClick={() => handleEditLead(card)}>Edit</MenuItem>
                  <MenuItem onClick={() => handleDeleteLead(card.id)}>Remove Card</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </li>
        ))}
      </ul>
    </Box>

        </Flex>
        <Flex justify="space-between" align="center" width="100%">
          <Text
            fontWeight="500"
            fontSize="16px"
            lineHeight="28px"
            letterSpacing="0.2px"
          >
            Payment History & Invoicing
          </Text>        
           <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisV />}
                  variant="ghost"
                  bg={"white"}
                />
                <MenuList>
                  <MenuItem onClick={() => handleExporthistory()}>Export </MenuItem>
                  {/* <MenuItem onClick={() => handleDeleteLead()}>Share </MenuItem> */}
                </MenuList>
              </Menu>
        </Flex>
        <Flex
          flex="1"
          borderRadius={"10px"}
          direction={{ base: "column", md: "row" }}
        >
          <Box border="1px solid #ccc" borderRadius={"10px"} width={"100%"}>
            <Box borderWidth="1px" mt={0}>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Checkbox
                          isChecked={selectedItems === leads.length}
                          onChange={handleSelectAll}
                        />
                      </Th>
                      <Th>Invoice</Th>
                      <Th>Campaign Name</Th>
                      <Th>Plan Type</Th>
                      <Th>Status</Th>
                      <Th>Ad Placement</Th>
                      <Th>Method</Th>
                      <Th>Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((lead) => (
                      <Tr key={lead.id}>
                        <Td>
                          <Checkbox
                            isChecked={lead.isSelected}
                            onChange={() => handleCheckboxChange(lead.id)}
                          />
                        </Td>
                        <Td>{lead.name}</Td>
                        <Td>{lead.email}</Td>
                        <Td>{lead.phone}</Td>
                        <Td>{lead.status}</Td>
                        <Td>{lead.placement}</Td>
                        <Td>{lead.method}</Td>
                        <Td>{lead.amount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              {/* <Box mt={4} display="flex" justifyContent="center">
                {Array.from({
                  length: Math.ceil(filteredLeads.length / itemsPerPage),
                }).map((_, idx) => (
                  <Button
                    key={idx}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => paginate(idx + 1)}
                    mx={1}
                    size="sm"
                    borderRadius="20px"
                  >
                    {idx + 1}
                  </Button>
                ))}
              </Box> */}
            </Box>

            {!isDesktop && (
              <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Options</DrawerHeader>
                  <DrawerBody>
                    <Button mb={2} w="100%">
                      {filteredLeads.length} Total Leads
                    </Button>
                    {/* <Button onClick={handleFileUpload} mb={2} w="100%">
                      Import Leads
                    </Button> */}
                    {/* <Button onClick={handleExportLeads} mb={2} w="100%">
                      Export Payment History
                    </Button> */}
                    <Button mb={2} w="100%" onClick={handleDeleteAllLeads}>
                      Delete All Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleAddLead}>
                      Add Leads
                    </Button>
                    <Button mb={2} w="100%">
                      Create Campaigns
                    </Button>
                    {/* <Button
                      mb={2}
                      w="100%"
                      onClick={() => handleAddLink(leads.id)}
                    >
                      Generate Link
                    </Button> */}
                    <Button mb={2} w="100%">
                      {selectedItems} Leads selected{" "}
                    </Button>
                  </DrawerBody>
                  <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            )}

            {showAddForm && (
              <Modal isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {isEditing ? "Edit Card" : "Add New Card"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl isInvalid={formErrors.name}>
                      <FormLabel>Name on card</FormLabel>
                      <Input
                       name="name"
                        value={createLead.name}
                        onChange={handleinpuchange}
                      />
                      { formErrors.name && (
                        <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Card Number</FormLabel>
                      <Input
                        name="card_number"
                        value={createLead.card_number}
                        onChange={handleinpuchange}
                      />
                    </FormControl>
                    <FormControl >
                      <FormLabel>Expiration date</FormLabel>
                      <Input
                        name="exp_date"
                        value={createLead.exp_date}
                        onChange={handleinpuchange}
                      />
                      {formErrors.exp_date && (
                        <FormErrorMessage>{formErrors.exp_date}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>CVV</FormLabel>
                      <Input
                        name="cvv"
                        value={createLead.cvv}
                        onChange={handleinpuchange}
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button colorScheme="blue" mr={3} onClick={handleSubmit}> */}
                    <Button colorScheme="blue" mr={3} onClick={CreateLead}>
                      {isEditing ? "Update" : "Add"}
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => setShowAddForm(false)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Box>
          {showLinkForm && (
            <Modal isOpen={showLinkForm} onClose={() => setShowLinkForm(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Link</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Link</FormLabel>
                    <Input value={newLink} isReadOnly />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  {/* <Button colorScheme="blue" onClick={handleLinkSubmit}>
                    Save Link
                  </Button> */}
                  <Button onClick={() => setShowLinkForm(false)}>Cancel</Button>
                  <Button
                    onClick={() => navigator.clipboard.writeText(newLink)}
                  >
                    Copy Link
                  </Button>
                  <Button
                    onClick={() => {
                      if (navigator.share) {
                        navigator.share({
                          title: "Lead Link",
                          url: newLink,
                        });
                      } else {
                        alert("Sharing is not supported in this browser");
                      }
                    }}
                  >
                    Share Link
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </Flex>
        <ToastContainer />
      </Flex>
    </>
  );
}
