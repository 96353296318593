import React from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  Badge,
  Icon,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { BsSend } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { RiAttachment2 } from 'react-icons/ri';
import { useConversation } from '../zustand/useConversation';
import { extractTime } from '../utils/extractTime';

const Message = ({ message, authUser }) => {
  const { messages, setMessages, selectedConversation } = useConversation();

  const formattedTime = extractTime(message.createdAt);
  const fromMe = message.senderId === authUser?.id;
  const senderName = authUser.first_name + ' ' + authUser.last_name;
  const receiverName =
    selectedConversation.first_name + ' ' + selectedConversation.last_name;
  // console.log('receiverName is', receiverName);
  // console.log('setSelectConversation', selectedConversation);
  return (
    <Box

    // sx={customScrollbarStyles}
    >
      <Flex
        align="end"
        mb={4}
        ml={fromMe ? '50%' : ''}
        mr={fromMe ? '' : '50%'}
      >
        <Avatar
          name={fromMe ? senderName : receiverName}
          src="https://bit.ly/broken-link"
          mr={4}
        />
        <Flex direction="column" flex="1">
          <Flex align="start" mb={2}>
            <Flex flexDirection="column">
              <Text fontWeight="bold" m={0}>
                {fromMe ? senderName : receiverName}
              </Text>
              <Text fontSize="xs" color="#82DE01" m={0}>
                {formattedTime}
              </Text>
            </Flex>

            <Box bg="red" ml={2} w={2} h={2} mt={2} borderRadius="full"></Box>
          </Flex>

          <Box bg="blue.50" w="90%" p={4} borderRadius="0px 12px 12px 0px">
            <Text m={'auto'}>{message.message}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Message;
