// TabsContainer.js
import React, { useState } from "react";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue } from "@chakra-ui/react";
import LeadsReport from "./LeadReport";
import DealsReport from "./DealReport";
import ContactReport from "./ContactReport";
import CompanyReport from "./CompanyReport";
import ProjectReport from "./ProjectReport";
import TaskReport from "./TaskReport";
import CampaignChart from "../Dashboard/Campaignsgraph";


const ReportContainer = () => {

  const [filter, setFilter] = useState("Leads Report");
  const tabListDirection = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Box p={4} overflowX="auto" >
      <Tabs variant="enclosed" colorScheme="blue">
      <Box p={3} borderWidth="1px" borderRadius="md" bg="white"  overflowX="auto" >
         <TabList display="flex" justifyContent="space-between"  flexDirection={tabListDirection} borderBottom={"none"} 
          width={tabListDirection === "column" ? "80%" : "80%"}>
          <Tab
            onClick={() => setFilter("Leads Report")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Leads Report
          </Tab>
          
          <Tab  onClick={() => setFilter("Deals Report")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}>Deals Report</Tab>
          <Tab  onClick={() => setFilter("Contact Report")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}>Contact Report</Tab>
          <Tab  onClick={() => setFilter("Company Report")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}>Company Report</Tab>
          <Tab  onClick={() => setFilter("Project Report")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}>Project Report</Tab>
          <Tab  onClick={() => setFilter("Task Report")}
            _selected={{
              borderBottom: "3px solid red",
              color: "red",
            }}
            _hover={{ color: "blue" }}
            fontFamily={"Noto Sans"}
            fontSize={"14px"}
            fontWeight={"500"}>Task Report</Tab>
        </TabList>
</Box>
<TabPanels>
  <TabPanel>
    {filter === "Leads Report" && <LeadsReport />}
  </TabPanel>
  <TabPanel>
    {filter === "Deals Report" && <DealsReport />}
  </TabPanel>
  <TabPanel>
    {filter === "Contact Report" && <ContactReport />}
  </TabPanel>
  <TabPanel>
    {filter === "Company Report" && <CompanyReport />}
  </TabPanel>
  <TabPanel>
    {filter === "Project Report" && <ProjectReport />}
  </TabPanel>
  <TabPanel>
    {filter === "Task Report" && <TaskReport />}
  </TabPanel>
</TabPanels>

      </Tabs>
    </Box>
  );
};

export default ReportContainer;
