import { Flex, Select, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const data = {
  series: [
    {
      name: 'Value 1',
      data: [20, 40, 60, 90, 50, 70, 30, 50, 60, 30, 40, 30],
    },
   
  ],
  categories: [
    '5k', '10k', '15k', '20k', '25k', '30k', '35k', '40k', '45k', '50k', '55k', '60k',
  ],
};

const CampaignChart = () => {
  const [selectedMonth, setSelectedMonth] = useState('monthly');

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const options = {
    chart: {
      type: 'area', // Changed to 'area' for an area chart
      height: 400,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [2, 2, 2],
      curve: 'smooth', // Change curve to smooth for a more natural area chart look
    },
    colors: ['#4285F4', '#FF5722', '#FFC107'],
    xaxis: {
      categories: data.categories,
      title: {
        text: '',
      },
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'top',
    },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px',
        padding: '20px 20px 40px 20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Flex justify="space-between" align="center" px="5">
        <Text
          as="h2"
          fontFamily="Poppins, sans-serif"
          fontWeight="500"
          fontSize="18px"
          lineHeight="27px"
          m="0"
        >
          Campaigns
        </Text>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          size="sm"
          maxWidth="120px"
          borderRadius="md"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </Flex>
      <ReactApexChart
        options={options}
        series={data.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default CampaignChart;
