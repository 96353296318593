import {
  Box,
  Flex,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  HStack,
  Image,
  Avatar,
  AvatarBadge,
  Badge,
  InputLeftElement,
  Grid,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon, BellIcon } from "@chakra-ui/icons";
import { FaGlobe, FaBars, FaClipboardCheck } from "react-icons/fa";
import { FaCalendarAlt, FaCog, FaHeadset } from "react-icons/fa";
import addheader from "./Headericons/add-header.png";
import groupheader from "./Headericons/group-header.png";

import { useState, useEffect } from "react";
import MarcomLogo from "../image/NewMarcomLogo.png";
import MarcomLogo1 from "../image/MarcomFull.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Notifications from "../Notifications/Notifications";
import Style from "./Header.module.css";
import { IoMdNotificationsOutline } from "react-icons/io";
import profile from "./Headericons/profile.png"
import Activity from "./Headericons/Activity.png"
import Analytics from "./Headericons/Analytics.png"
import Company from "./Headericons/Company.png"
import deals from "./Headericons/deals.png"
import target from "./Headericons/target.png"
import Tasks from "./Headericons/Tasks.png"
import salesPipeline from "./Headericons/Sales-Pipeline.png"
import UserNetworking from "./Headericons/User-Networking.png"
import { useAuthContext } from "../CommunityGroup/context/AuthContext";



export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [notificationCount, setNotificationCount] = useState(1);
  const colorModeValue = useColorModeValue("gray.800", "gray");
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuthUser } = useAuthContext();
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationsClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleViewAllClick = () => {
    setShowNotifications(false);
    navigate("/notifications");
  };
  const [userName, setUserName] = useState("");
  useEffect(() => {
    const handleUserDataChange = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        const fullName = `${userData.first_name} ${userData.last_name}`;
        setUserName(fullName);
      }
    };
    // Initial set
    handleUserDataChange();
    // Listen for custom 'userDataChanged' event
    window.addEventListener("userDataChanged", handleUserDataChange);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("userDataChanged", handleUserDataChange);
    };
  }, []);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      setIsAuthenticated(true);
      setUser(userData);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userData");
    setAuthUser(null);
    setIsAuthenticated(false);
    navigate("/signin");
  };

  const data = [
    {
      name: 'Contacts',
      description:"Add New Contact",
      avatar:profile,
    },
    {
      name: 'Pipelines',
      description:"Add New Pipeline",
      avatar:salesPipeline,
    },
    {
      name: 'Activities',
      description:"Add New Activity",
      avatar:Activity,
    },
    {
      name: 'Analytics',
      description:"Add New Information",
      avatar:Analytics,
    },
    {
      name: 'Projects',
      description:"Add New Project",
      avatar:target,
    },
    {
      name: 'Deals',
      description:"Add New Deals",
      avatar:deals,
    },
    {
      name: 'Leads',
      description:"Add New Leads",
      avatar:UserNetworking,
    },
    {
      name: 'Company',
      description:"Add New Company",
      avatar:Company,
    },
    {
      name: 'Tasks',
      description:"Add New Task",
      avatar:Tasks,
    },
     {
      name: 'Compaign',
      description:"Add New Compaign",
      avatar:FaClipboardCheck,
    },
  ]
  const languages = [
    { name: "English", flag: "https://flagcdn.com/w20/us.png" },
    { name: "Hindi", flag: "https://flagcdn.com/w20/in.png" },
    { name: "Spanish", flag: "https://flagcdn.com/w20/es.png" },
    { name: "French", flag: "https://flagcdn.com/w20/fr.png" },
  ];

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };

  const isDashboard = location.pathname !== "/";

  return (
    <Box position="sticky" top="0px" zIndex={"1"}>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"50px"}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        // justify={"space-between"}
      >
        <Link to={"/"}>
          <Box
            as="a"
            display="inline-block"
            // transition="transform 0.3s ease-in-out"
            // _hover={{ transform: "scale(1.1)", filter: "brightness(1.2)" }}
          >
            <Flex>
              <Box
                as="img"
                src={MarcomLogo}
                alt="Marcom Logo"
                // boxSize={useBreakpointValue({ base: "50px", md: "90px" })}
                width={"200px"}
                height={"80px"}
                mb={"5px"}
              />

              {/* <Box
              as="img"
              src={MarcomLogo1}
              alt="Marcom Logo"
              boxSize={useBreakpointValue({
                base: "50px",
                sm: "70px",
                md: "90px",
              })}
              objectFit="contain"
              display="block"
              mx="auto"
            /> */}
            </Flex>
          </Box>
        </Link>
        {isDashboard && isAuthenticated && (
          <Box zIndex="dropdown" ml={"10%"}>
            <InputGroup maxW="400px" flex="1" w={{ base: "auto", lg: "300px" }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                borderRadius="md"
                bg="gray.100"
              />
            </InputGroup>
          </Box>
        )}
        <Flex
          width={"90%"}
          display={{ base: "none", md: "flex" }}
          align="center"
          justifyContent={"end"}
        >
          {isDashboard && isAuthenticated && (
            
            <HStack spacing={3}>
             <Menu>
  <MenuButton
    as={IconButton}
    icon={<img src={addheader} alt="addheader" title="addheader" />}
    aria-label="Calendar"
    bg="green.100"
    color="green.600"
    borderRadius="md"
    size="md"
  />
  <MenuList>
  <Grid templateColumns="repeat(2, 1fr)" gap={1} >
  {data.map((elem, index) => (
        <MenuItem key={index} onClick={() => console.log(`Option ${index + 1}`)}>
          <Flex align="center">
            {/* Image on the left */}
            <IconButton
                icon={
                  <img
                    src={elem.avatar}
                    alt={elem.avatar}
                    Title={elem.avatar}
                  />
                }
                aria-label="Settings"
                bg="#676DFF"
                color="white"
                borderRadius="md"
                size="md"
              />
            {/* Name and description on the right */}
            <Box mt="4" ml="3">
             <Text fontWeight="500" lineHeight="0">{elem.name}</Text>
             <Text  fontWeight="400" fontSize="sm"  lineHeight="0" color="gray.500">
                {elem.description}
              </Text>
            </Box>
          </Flex>
        </MenuItem>
      ))}
    </Grid>

  </MenuList>
</Menu>
              <IconButton
                icon={
                  <img
                    src={groupheader}
                    alt="groupheader"
                    Title="groupheader"
                  />
                }
                aria-label="Settings"
                bg="yellow.100"
                color="yellow.600"
                borderRadius="md"
                size="md"
              />
              <IconButton
                icon={<FaHeadset />}
                aria-label="Language"
                bg="orange.100"
                color="orange.600"
                borderRadius="md"
                size="md"
              />
              <Box position="relative" display="inline-block">
                <IconButton
                  icon={<IoMdNotificationsOutline />}
                  aria-label="Notifications"
                  bg="red.100"
                  color="red.600"
                  borderRadius="md"
                  size="md"
                />
                <Badge
                  position="absolute"
                  top="10"
                  right="-10"
                  bg="red.500"
                  color="white"
                  borderRadius="full"
                  fontSize="0.8em"
                  px="2"
                ></Badge>
              </Box>

              {/* User Profile */}
              {isAuthenticated ? (
                <Menu>
                  <MenuButton as={HStack} spacing={2} cursor="pointer">
                    <Flex align="center">
                      {/* Avatar Full Height */}
                      <IconButton aria-label="User Profile" variant="unstyled">
                        <Avatar name={userName} size="sm" height="100%" />
                      </IconButton>

                      {/* User Info with Chevron Icon */}
                      <Box ml={2} textAlign="left" marginTop={3}>
                        <HStack spacing={1}>
                          <Flex>
                            {" "}
                            <Text fontWeight="bold">{userName}</Text>
                            <ChevronDownIcon margin={1} />{" "}
                          </Flex>
                        </HStack>
                        {/* <Text fontSize="sm" color="gray.500">Technical Lead</Text> */}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <Link to="/Profile">
                      <MenuItem>Profile</MenuItem>
                    </Link>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <Link to="/signin">
                  <Text color="blue.500" fontWeight="bold">
                    Sign In
                  </Text>
                </Link>
              )}
            </HStack>
          )}
          {!isAuthenticated && (
            <>
              <Link to="/signin">
                <Button
                  fontSize="sm"
                  mr={2}
                  fontWeight={600}
                  color={"white"}
                  bg={"red.500"}
                  _hover={{ bg: "red.400" }}
                >
                  Sign In
                </Button>
              </Link>
              <Link to="/signup">
                <Button
                  fontSize="sm"
                  fontWeight={600}
                  color={"white"}
                  bg={"red.500"}
                  _hover={{ bg: "red.400" }}
                >
                  Sign Up
                </Button>
              </Link>
            </>
          )}

          {location.pathname === "/" && isAuthenticated && (
            <Link to="/Dashboard">
              <Button
                fontSize="sm"
                ml={2}
                fontWeight={600}
                color={"white"}
                bg={"red.500"}
                _hover={{ bg: "red.400" }}
              >
                Dashboard
              </Button>
            </Link>
          )}
        </Flex>

        <Flex display={{ base: "flex", md: "none" }} align="center">
          <IconButton
            aria-label="Open Menu"
            icon={<FaBars />}
            onClick={onToggle}
            variant="ghost"
            mr={2}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      <MobileNavItem label="Products" children={[]} />
      <MobileNavItem label="Company" children={[]} />
      <MobileNavItem label="Customers" />
    </Stack>
  );
};

const MobileNavItem = ({ label }) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        href="#"
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};
