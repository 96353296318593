import {
  Box,
  Input,
  VStack,
  Text,
  Avatar,
  HStack,
  Badge,
  Flex,
  AvatarBadge,
} from '@chakra-ui/react';
import React from 'react';
import { useSocketContext } from '../context/SocketContext';
import { useConversation } from '../zustand/useConversation';
const Conversation = ({ conversation }) => {
  const { selectedConversation, setSelectedConversation, messages } =
    useConversation();
  // console.log('selectedcovo', conversation);
  // console.log('messages in coversation', messages);
  const lastmessage = messages.filter(
    (message) => message.conversationId === conversation.id
  );
  console.log('messages in coversation', lastmessage);
  const isSelected = selectedConversation?.id === conversation.id;
  const { onlineUsers } = useSocketContext();
  // console.log('online users', onlineUsers);
  const isOnline = onlineUsers.includes(String(conversation.id));
  // console.log('conversation id', conversation.id);
  console.log('is online is', isOnline);
  return (
    <Box
      bg={isSelected ? 'red.50' : ''}
      w="full"
      maxW={'80%'}
      onClick={() => setSelectedConversation(conversation)}
    >
      <Flex justifyContent="center" gap={50} w="full" alignItems="center">
        <Flex justifyContent="space-between" gap={18} alignItems="start">
          <Avatar
            name={conversation.first_name + ' ' + conversation.last_name}
            src="https://bit.ly/broken-link"
          >
            {isOnline ? <AvatarBadge boxSize="0.90em" bg="red.500" /> : null}
          </Avatar>
          <Flex
            flexDirection="column"
            justifyContent="start"
            gap={1}
            alignItems="start"
          >
            <Text fontWeight={500} m={0} p={0}>
              {conversation.first_name + ' ' + conversation.last_name}
            </Text>
            <Text fontWeight={400} mb={0} p={0} fontSize="xs">
              May be on Monday?
            </Text>
          </Flex>
          {/* {isOnline ? (
            <Box bg="red" w={2} h={2} mt={2} borderRadius="full"></Box>
          ) : null} */}
        </Flex>
        <Text mt={2} ml={0} fontSize="xs" color="#82DE01">
          9:23am
          {/* <Box
            w="20px"
            h="20px"
            bg="red.500"
            color="white"
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="12px"
            fontWeight="semibold"
            ml={2}
          >
            1
          </Box> */}
        </Text>
      </Flex>
    </Box>
  );
};

export default Conversation;
