import React from "react";
import { Box, Text } from "@chakra-ui/react";

const CompanyProfile = () => {
  return (
    <Box
      gap={6}
      fontSize="sm"
      bg="white"
      display="flex"
      border="1px solid #D6D6D6"
      maxHeight={{ base: "auto", sm: "auto", md: "auto", lg: "30px" }} // Adjust maxHeight for different screen sizes
      alignItems="center"
      p={4}
      px="4"
      borderRadius="5px"
      justifyContent="center"
      width={{ base: "100%", md: "auto" }} // Make it full width on small screens
    >
      <Text marginTop="20px"    fontFamily="Poppins"
          fontSize="15px"
          fontWeight="500"
          lineHeight="19.5px" >
        Client Name:{" "}
        <Text as="span" fontFamily="Poppins"
          fontSize="13px"
          fontWeight="400"
          lineHeight="19.5px" >
          Acme Corp
        </Text>
      </Text>
      <Text marginTop="20px"    fontFamily="Poppins"
          fontSize="15px"
          fontWeight="500"
          lineHeight="19.5px">
        Client Department:{" "}
        <Text as="span" fontFamily="Poppins"
          fontSize="13px"
          fontWeight="400"
          lineHeight="19.5px" >
          Marketing
        </Text>
      </Text>
      <Text marginTop="20px"    fontFamily="Poppins"
          fontSize="15px"
          fontWeight="500"
          lineHeight="19.5px">
        Client ID:{" "}
        <Text as="span" fontFamily="Poppins"
          fontSize="13px"
          fontWeight="400"
          lineHeight="19.5px" >
          CLNT-12345
        </Text>
      </Text>
    </Box>
  );
};

export default CompanyProfile;
