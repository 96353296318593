import React, { useState, useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useSocketContext } from '../context/SocketContext';
import { useConversation } from '../zustand/useConversation';
import Conversation from './Conversation';
import { useGetConversations } from '../hooks/useGetConversation';
const Conversations = () => {
  const [activeTab, setActiveTab] = useState('All');
  const { loading, conversations } = useGetConversations();

  return (
    <Box>
      <Flex mb={10} ml={10} align="center" gap={8}>
        {['All', 'Community', 'Groups'].map((tab) => (
          <Box
            fontSize="16px"
            fontWeight={400}
            mr={8}
            key={tab}
            onClick={() => setActiveTab(tab)}
            cursor="pointer"
          >
            <Text
              color={activeTab === tab ? 'red.500' : 'black'}
              fontWeight={activeTab === tab ? 'bold' : 'normal'}
              mb={1}
            >
              {tab}
            </Text>
            {activeTab === tab && (
              <Box height="2px" bg="red.500" width="100%" />
            )}
          </Box>
        ))}
      </Flex>
      <Flex
        flexDirection="column"
        gap={6}
        justifyContent="center"
        alignItems="center"
      >
        {conversations.map((conversation, idx) => (
          <Conversation
            key={conversation.id}
            conversation={conversation}
            lastIdx={idx === conversations.length - 1}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Conversations;
