import React from 'react';
import { Box, Flex, Text, Avatar, Badge, Select, Table, Thead, Tbody, Image,Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import dotssymbol from "./icons/dotssymbol.png"
import companyicon from "./icons/companyicon.png"

const RecentlyCreatedLeads = () => {
  const leadsData = [
    {
      name: 'Collins',
      company: 'NovaWave LLC',
      location: 'Newyork, USA',
      phone: '+1 875455453',
      status: 'Not Contacted',
      statusColor: 'blue',
      avatar:companyicon,
    },
    {
      name: 'Konopelski',
      company: 'BlueSky Industries',
      location: 'Winchester, KY',
      phone: '+1 989757485',
      status: 'Contacted',
      statusColor: 'orange',
      avatar: companyicon,
    },
    {
      name: 'Adams',
      company: 'SilverHawk',
      location: 'Jamestown, NY',
      phone: '+1 546555455',
      status: 'Contacted',
      statusColor: 'orange',
      avatar: companyicon,
    },
    {
      name: 'Schumm',
      company: 'SummitPeak',
      location: 'Compton, RI',
      phone: '+1 454478787',
      status: 'Not Contacted',
      statusColor: 'blue',
      avatar: companyicon,
    },
  ];

  return (
    <Box maxH="auto">
          <Flex align="center" justify="space-between" mb={4}>

       <Flex align="center">
      <Image src={dotssymbol} alt="dotssymbol" title="dotssymbol" mr="2" w="10px" h="10px" />
      <Text as="h2" fontFamily="Poppins, sans-serif" fontWeight="500" fontSize="18px" lineHeight="27px" m="0">
        Recently Created Leads
      </Text>
    </Flex>
    <Select size="sm" maxWidth="120px" borderRadius="md" defaultValue="Last 30 Days">
      <option>Last 7 Days</option>
      <option>Last 15 Days</option>
      <option>Last 30 Days</option>
      <option>Last 60 Days</option>
    </Select>
</Flex>
      <TableContainer  w="auto" mt={"20px"}   overflowX="unset">
      <Table variant="simple" size="sm" p="4">
        <Thead bg={"#EDEDED"} p="4">
          <Tr>
          <Th className='table-header-style'>Lead Name</Th>
      <Th className='table-header-style'>Company Name</Th>
      <Th className='table-header-style'>Phone</Th>
      <Th className='table-header-style'>Lead Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {leadsData.map((lead, index) => (
            <Tr key={index}>
              <Td>
                <Flex align="center">
                  <Text fontWeight="400" fontSize="14px" lineHeight="21px" color="#6F6F6F" fontFamily="Poppins">{lead.name}</Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Avatar src={lead.avatar} size="sm" mr="2"  />
                  <Box mt="2">
                    <Text fontWeight="medium" fontSize="sm" mb="0.5">{lead.company}</Text>
                    <Text fontSize="xs" color="gray.500" lineHeight="2">{lead.location}</Text>
                  </Box>
                </Flex>
              </Td>
              <Td>
              <Box mt="2">
                <Text fontWeight="400" fontSize="14px" lineHeight="21px" color="#6F6F6F" fontFamily="Poppins">{lead.phone}</Text>
                </Box>
              </Td>
              <Td>
              <Badge
  bg={lead.statusColor}
  borderRadius="md"
  p="2"
  display="flex"
  alignItems="center"
  justifyContent="center"
  textAlign="center"
  width="auto" // Adjust width based on content size
  fontWeight="600"
  color="white"
  fontFamily="Poppins"
>
  {lead.status}
</Badge>

              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      </TableContainer>
    </Box>
  );
};

export default RecentlyCreatedLeads;
