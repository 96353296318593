import {
  Box,
  Grid,
  GridItem,
  Text,
  Heading,
  Flex,
  Icon,
} from "@chakra-ui/react";
import {
  FaFlag,
  FaEnvelope,
  FaProductHunt,
  FaUser,
  FaBook,
} from "react-icons/fa";

const Wisdom = () => {
  return (
    <Box padding="4" maxWidth="1000px" mx="auto">
      {/* Header Section */}
      <Box
        bg="red.400"
        color="white"
        p="6"
        borderRadius="md"
        textAlign="center"
      >
        <Heading size="lg">Marcom Guide Documentation</Heading>
        <Text mt="2" color="white">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          malesuada pellentesque auctor. Nulla ac erat est. Vestibulum facilina,
          nulla vitae tristique facilisis, risus turpis tincidunt tortor, in
          scelerisque tellus felis quis est. Etiam condimentum tempor metus eget
          volutpat.
        </Text>
      </Box>

      {/* Grid Layout for Links */}
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gap="4"
        mt="6"
      >
        <GridItem
          p="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex direction="column" align="center">
            {/* <Icon as={FaUser} boxSize="6" color="gray.600" /> */}
            <Text fontWeight="md" mt="2">
              Get Started
            </Text>
            <Text color="red.500">Welcome To Marcom Guides</Text>
          </Flex>
        </GridItem>

        <GridItem
          p="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex direction="row" align="center" gap={"4"} ml={"10"}>
            <Icon as={FaFlag} boxSize="6" color="gray.600" />
            <Flex direction="column" align="center">
              <Text fontWeight="md" mt="2">
                What's New
              </Text>
              <Text color="red.500">What's New?</Text>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem
          p="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex direction="row" align="center" gap={"4"} ml={"10"}>
            <Icon as={FaEnvelope} boxSize="6" color="gray.600" />
            <Flex direction="column" align="center">
              <Text fontWeight="md" mt="2" color="red.500">
                New Release 2.0
              </Text>
              <Text>New Release 2.0</Text>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem
          p="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex direction="row" align="center" gap={"4"} ml={"10"}>
            <Icon as={FaProductHunt} boxSize="6" color="gray.600" />
            <Flex direction="column" align="center">
              <Text fontWeight="md" mt="2">
                Products
              </Text>
              <Text color="red.500">User Products</Text>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem
          p="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex direction="row" align="center" gap={"4"} ml={"10"}>
            <Icon as={FaUser} boxSize="6" color="gray.600" />
            <Flex direction="column" align="center">
              <Text fontWeight="md" mt="2">
                User Guide
              </Text>
              <Text color="red.500">User Guides</Text>
            </Flex>
          </Flex>
        </GridItem>

        <GridItem
          p="4"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex direction="row" align="center" gap={"4"} ml={"10"}>
            <Icon as={FaBook} boxSize="6" color="gray.600" />
            <Flex direction="column" align="center">
              <Text fontWeight="md" mt="2">
                Documentation
              </Text>
              <Text color="red.500">Documentation</Text>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Wisdom;
