import React from 'react';
import { Box, Flex, Text, Avatar, Select, Table, Thead, Tbody, Image, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import dotssymbol from "./icons/dotssymbol.png";
import companyicon from "./icons/companyicon.png";
import "./Dashboard.css"

const UpcomingSessions = () => {
  const leadsData = [
    {
      name: 'Collins',
      company: 'NovaWave LLC',
      location: 'Newyork, USA',
      phone: '+1 875455453',
      status: 'Not Contacted',
      sessionType: "Lower call",
      statusColor: 'blue',
      countdown: "02:10:09 Hrs",
      datetime: "Oct 08 11:00pm",
      avatar: companyicon,
    },
    {
      name: 'Konopelski',
      company: 'BlueSky Industries',
      location: 'Winchester, KY',
      phone: '+1 989757485',
      sessionType: "Discovery call",
      status: 'Contacted',
      countdown: "02:10:09 Hrs",
      datetime: "Oct 09 11:00pm",
      statusColor: 'orange',
      avatar: companyicon,
    },
    {
      name: 'Adams',
      company: 'SilverHawk',
      location: 'Jamestown, NY',
      phone: '+1 546555455',
      status: 'Contacted',
      sessionType: "Presentation call",
      statusColor: 'orange',
      countdown: "01:10:09 Hrs",
      datetime: "Oct 19 11:00pm",
      avatar: companyicon,
    },
    {
      name: 'Schumm',
      company: 'SummitPeak',
      location: 'Compton, RI',
      phone: '+1 454478787',
      status: 'Not Contacted',
      sessionType: "Dummy call",
      countdown: "02:10:09 Hrs",
      datetime: "Oct 29 11:00pm",
      statusColor: 'blue',
      avatar: companyicon,
    },
  ];

  return (
    <Box width="100%">
      <Flex align="center" justify="space-between" mb={4}>
        <Flex align="center">
          <Image src={dotssymbol} alt="dotssymbol" title="dotssymbol" mr="2" w="10px" h="10px" />
          <Text as="h2" fontFamily="Poppins, sans-serif" fontWeight="500" fontSize="18px" lineHeight="27px" m="0">
            Upcoming Sessions
          </Text>
        </Flex>
        <Select size="sm" maxWidth="120px" borderRadius="md" defaultValue="monthly">
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </Flex>
      
      <TableContainer width="100%" >
        <Table variant="simple" size="sm">
          <Thead bg="#EDEDED">
            <Tr>
              <Th className='table-header-style'>Date & Time</Th>
              <Th className='table-header-style'>Session Type</Th>
              <Th className='table-header-style' >Company Name</Th>
              <Th className='table-header-style'>Count Down</Th>
              <Th className='table-header-style' >Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leadsData.map((lead, index) => (
              <Tr key={index}>
                <Td textAlign="center" maxWidth="50px" isTruncated>
                  <Text fontWeight="400" fontSize="14px" lineHeight="21px" color="#262A2A" fontFamily="Poppins">{lead.datetime}</Text>
                </Td>
                <Td textAlign="center" maxWidth="50px" isTruncated>
                  <Text  fontWeight="400" fontSize="14px" lineHeight="21px" color="#262A2A" fontFamily="Poppins">{lead.sessionType}</Text>
                </Td>
                <Td textAlign="center">
                  <Flex align="center">
                    <Avatar src={lead.avatar} size="sm" mr="2" />
                    <Box mt="2">
                    <Text fontWeight="medium" fontSize="sm" mb="0.5">{lead.company}</Text>
                    <Text fontSize="xs" color="gray.500" lineHeight="2">{lead.location}</Text>
                    </Box>
                  </Flex>
                </Td>
                <Td textAlign="center" maxWidth="50px">
                  <Text fontWeight="400" fontSize="14px" lineHeight="21px" color="#262A2A" fontFamily="Poppins">{lead.countdown}</Text>
                </Td>
                <Td textAlign="center" maxWidth="20px">
                  <Text fontSize="lg">...</Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UpcomingSessions;
