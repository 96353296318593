import React from 'react'
import Header from '../Header/Header'
// import { Box, Button, Container, Flex, Heading, Input, InputGroup, Text,  } from '@chakra-ui/react'
  import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Input,
    Stack,
    Text,
    VStack,
  } from "@chakra-ui/react";
  import { ChatIcon, PhoneIcon } from "@chakra-ui/icons";
import { CiSearch } from "react-icons/ci";

const Support = () => {
  return (
    // <Container border={"solid red 2px"} p={"20px"} minW={"60vw"} mt={'20px'}>
    //   <Center minH="70vh" bg="gray.50">
    //     <VStack spacing={6}>
    //       <Text fontSize="2xl" fontWeight="normal" color="gray.700">
    //         We Are Glad to Hear From You!
    //       </Text>

    //       <Container position={'relative'}>
    //         <Box position={'absolute'} top={'15px'} px={'15px'} fontSize={'20px'}>
    //             <CiSearch/>
    //         </Box>
    //       <Input
    //         placeholder="Search"
    //         pl={'45px'}
    //         size="lg"
    //         maxW="400px"
    //         borderRadius="md"
    //         borderColor="gray.300"
    //         _placeholder={{ color: "gray.500" }}
    //         />
    //         </Container>

    //       <Flex gap={8}>
    //         <Box
    //           bg="red.300"
    //           p={6}
    //           borderRadius="lg"
    //           color="white"
    //           textAlign="center"
    //           shadow="md"
    //           _hover={{ bg: "red.300" }}
    //         >
    //           <ChatIcon boxSize={6} mb={3} />
    //           <Text fontSize="lg" fontWeight="bold" color={'white'}>
    //             Get Support
    //           </Text>
    //           <Text fontSize="sm" color={'white'}>
    //             Need help? Connect with our support team.
    //           </Text>
    //         </Box>

    //         <Box
    //           bg="red.300"
    //           p={6}
    //           borderRadius="lg"
    //           color="white"
    //           textAlign="center"
    //           shadow="md"
    //           _hover={{ bg: "red.300" }}
    //         >
    //           <PhoneIcon boxSize={6} mb={3} />
    //           <Text fontSize="lg" fontWeight="bold" color={'white'}>
    //             Contact Sales
    //           </Text>
    //           <Text fontSize="sm" color={'white'}>
    //             For high volume inquiries, talk to our sales team.
    //           </Text>
    //         </Box>
    //       </Flex>
    //     </VStack>
    //   </Center>
    // </Container>
    <Container
      border="solid red 2px"
      p={{ base: "10px", md: "20px" }}
      pt={{base:"50px",md:"5px"}}
      minW="60vw"
      minH={"90vh"}
      mt={{ base: "10px", md: "20px" }}
      ml={{base:"20px",md:"20%"}}
      bg={'white'}
    >
      <Center minH="70vh" bg="white">
        <VStack spacing={{ base: 4, md: 6 }}>
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight="normal"
            color="black"
            ml={{base:'20px'}}
          >
            We Are Glad to Hear From You!
          </Text>

          <Container position="relative">
            <Box position="absolute" top="15px" px="15px" fontSize="20px">
              <CiSearch />
            </Box>
            <Input
              placeholder="Search"
              pl="45px"
              size="lg"
              maxW={{ base: "80%", sm: "600px" }}
              borderRadius="md"
              borderColor="gray.300"
              _placeholder={{ color: "gray.500" }}
            />
          </Container>

          <Flex
            direction={{ base: "column", md: "row" }}
            gap={{ base: 4, md: 8 }}
          >
            <Box
              bg="red.300"
              p={6}
              borderRadius="lg"
              color="white"
              textAlign="center"
              shadow="md"
              _hover={{ bg: "red.300" }}
            >
              <ChatIcon boxSize={6} mb={3} />
              <Text fontSize="lg" fontWeight="bold" color="white">
                Get Support
              </Text>
              <Text fontSize="sm" color="white">
                Need help? Connect with our support team.
              </Text>
            </Box>

            <Box
              bg="red.300"
              p={6}
              borderRadius="lg"
              color="white"
              textAlign="center"
              shadow="md"
              _hover={{ bg: "red.300" }}
            >
              <PhoneIcon boxSize={6} mb={3} />
              <Text fontSize="lg" fontWeight="bold" color="white">
                Contact Sales
              </Text>
              <Text fontSize="sm" color="white">
                For high volume inquiries, talk to our sales team.
              </Text>
            </Box>
          </Flex>
        </VStack>
      </Center>
    </Container>
  );
};
 

export default Support
