import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  FormErrorMessage,
  InputRightElement,
  Image,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImage from '../image/MarcomLogo.jpg';
import marketingImage1 from '../image/SignUplogo.svg';
import marketingImage2 from '../image/SigninLogo.svg';
import { useAuthContext } from '../CommunityGroup/context/AuthContext';
export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [currentImage, setCurrentImage] = useState(marketingImage1);
  const { setAuthUser } = useAuthContext();
  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === marketingImage1 ? marketingImage2 : marketingImage1
      );
    }, 3000);

    return () => clearInterval(imageInterval);
  }, []);

  const validateLoginForm = () => {
    let errors = {};
    if (!email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid.';
    }
    if (!password) {
      errors.password = 'Password is required.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateResetForm = () => {
    let errors = {};
    if (!resetEmail) {
      errors.resetEmail = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(resetEmail)) {
      errors.resetEmail = 'Email is invalid.';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (validateLoginForm()) {
      const userData = { email, password };
      console.log('userData', userData);
      const localstorage = localStorage.setItem(
        'userData',
        JSON.stringify(userData)
      );
      const user = localstorage.userData;
      setAuthUser({ user });
      console.log('localstorage', localstorage);
      toast.success('Login Successful!');
    }
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    if (validateResetForm()) {
      toast.info('Password reset email sent!');
      setForgotPassword(false);
    }
  };

  const openForgotPasswordForm = () => {
    setForgotPassword(true);
  };

  const closeForgotPasswordForm = () => {
    setForgotPassword(false);
    setResetEmail('');
    setErrors({});
  };

  return (
    <>
      <Box
        bg={useColorModeValue('gray.50', 'gray.800')}
        borderBottom={'1px solid lightgray'}
        href="/"
      >
        <Link href="/">
          <Image
            src={logoImage}
            width={'70px'}
            height={'70px'}
            mixBlendMode={'multiply'}
            pl={'10px'}
          ></Image>
        </Link>
      </Box>
      <Flex
        minH={'90vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}
        p={8}
      >
        <style>{containerKeyframes}</style>
        <Box
          display="flex"
          width={{ base: '90%', md: '80%', lg: '60%' }}
          height={{ base: '90%', md: '80%' }}
          borderRadius="15px"
          boxShadow={'lg'}
          overflow="hidden"
        >
          <Box
            flex={1}
            bgImage={`url(${currentImage})`}
            bgSize="cover"
            bgPos="center"
            borderRadius="15px 0 0 15px"
            _hover={{
              borderRadius: '15px',
              transition: '0.5s',
              border: '2px solid red',
            }}
          />
          <Box
            flex={1}
            p={8}
            bg={useColorModeValue('white', 'gray.700')}
            display="flex"
            alignItems="center"
            borderRadius="0 15px 15px 0"
            _hover={{
              borderRadius: '15px',
              transition: '0.5s',
              border: '2px solid red',
            }}
          >
            <Box w={'100%'}>
              <Stack align={'center'} mb={6}>
                <img
                  src={logoImage}
                  alt="Logo"
                  style={{ width: '1px', animation: 'fadeIn 2s ease-in' }}
                />
                <Heading fontSize={'4xl'} textAlign={'center'}>
                  {forgotPassword ? 'Forgot Password' : 'Login'}
                </Heading>
                <Text fontSize={'lg'} color={'gray.600'}>
                  to enjoy all of our cool features ✌️
                </Text>
              </Stack>
              {forgotPassword ? (
                <form onSubmit={handleForgotPasswordSubmit}>
                  <Stack spacing={4} mt={6}>
                    <FormControl
                      id="resetEmail"
                      isRequired
                      isInvalid={errors.resetEmail}
                    >
                      <FormLabel>Email address</FormLabel>
                      <Input
                        type="email"
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                      />
                      {errors.resetEmail && (
                        <FormErrorMessage>{errors.resetEmail}</FormErrorMessage>
                      )}
                    </FormControl>
                    <Stack spacing={10} pt={2}>
                      <Button
                        loadingText="Submitting"
                        size="lg"
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                          bg: 'blue.500',
                        }}
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </Stack>
                    <Stack pt={6}>
                      <Text align={'center'}>
                        <Link
                          color={'blue.400'}
                          onClick={closeForgotPasswordForm}
                        >
                          Cancel
                        </Link>
                      </Text>
                    </Stack>
                  </Stack>
                </form>
              ) : (
                <form onSubmit={handleLoginSubmit}>
                  <Stack spacing={4} mt={6}>
                    <FormControl id="email" isRequired isInvalid={errors.email}>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl
                      id="password"
                      isRequired
                      isInvalid={errors.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement h={'full'}>
                          <Button
                            variant={'ghost'}
                            onClick={() =>
                              setShowPassword((showPassword) => !showPassword)
                            }
                          >
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      {errors.password && (
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                      )}
                    </FormControl>
                    <Stack spacing={4} pt={2}>
                      <Text align={'right'}>
                        <Link
                          color={'blue.400'}
                          onClick={openForgotPasswordForm}
                        >
                          Forgot your password?
                        </Link>
                      </Text>
                    </Stack>
                    <Stack spacing={10} pt={2}>
                      <Button
                        loadingText="Logging in"
                        size="lg"
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                          bg: 'blue.500',
                        }}
                        type="submit"
                      >
                        Login
                      </Button>
                    </Stack>
                    <Stack pt={6}>
                      <Text align={'center'}>
                        Don't have an account?{' '}
                        <Link color={'blue.400'} as={'a'} href="/signup">
                          Sign up now
                        </Link>
                      </Text>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Flex>
    </>
  );
}

const containerKeyframes = `
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
