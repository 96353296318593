import { useState } from 'react';
import { useConversation } from '../zustand/useConversation.js';
import toast from 'react-hot-toast';
import { CallAPI } from '../../../Services/service.js';

const useSendMessages = () => {
  const [loading, setLoading] = useState(false);
  const { messages, setMessages, selectedConversation } = useConversation();
  const BASE_URL = 'http://localhost:5223';
  const sendMessage = async (message) => {
    setLoading(true);
    try {
      const res = await CallAPI(
        `${BASE_URL}/api/messages/${selectedConversation.id}`,
        message
      );
      console.log('res messages is', res);
      const data = res.data;

      if (!res) throw new Error('error in sending data');

      setMessages([...messages, res.data]);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { sendMessage, loading };
};
export { useSendMessages };
