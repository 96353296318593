import { useEffect, useState } from 'react';
import { useConversation } from '../zustand/useConversation';
import toast from 'react-hot-toast';
import { CallAPI } from '../../../Services/service';

const useGetMessages = () => {
  const [loading, setLoading] = useState(false);
  const { messages, setMessages, selectedConversation } = useConversation();
  const BASE_URL = 'http://localhost:5223';
  useEffect(() => {
    const getMessages = async () => {
      const data = JSON.parse(localStorage.getItem('userData'));
      const SenderId = data?.id;
      setLoading(true);
      try {
        const res = await CallAPI(
          `${BASE_URL}/api/messages/get-message/${selectedConversation.id}`,
          { id: SenderId }
        );
        console.log(selectedConversation.id);
        console.log('message res', res);

        if (!res) throw new Error('error in fetching data');

        setMessages(res.message);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedConversation?.id) getMessages();
  }, [selectedConversation?.id, setMessages]);

  return { messages, loading };
};
export { useGetMessages };
