import { Select, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./Dashboard.css";
import dotssymbol from "./icons/dotssymbol.png";

const BudgetOverview = () => {
  const [selectedMonth, setSelectedMonth] = useState("Jan");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Data for the chart
  const data = {
    series: [
      {
        name: "Budget Spent",
        data: [2000, 1500, 3000, 4000, 2500, 3200, 2100, 3400, 2900, 3700, 4100, 3800],
      },
      {
        name: "Budget Remaining",
        data: [1200, 800, 2000, 1000, 1500, 1800, 900, 1600, 1400, 1100, 1000, 1300],
      },
    ],
    categories: [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ],
  };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
                barSpacing: 10,    
        endingShape: 'rounded'
      },
    },
    colors: ["#42A5F5", "#FFCA28"],
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: data.categories,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val}`,
      },
    },
    legend: {
      position: "top",
    },
  };

  return (
    <div>
      <Flex justify="space-between" align="center" mb="5">
        <Flex align="center">
          <Image src={dotssymbol} alt="Symbol Icon" mr="2" boxSize="10px" />
          <Text
            as="h2"
            fontFamily="Poppins, sans-serif"
            fontWeight="500"
            fontSize="18px"
            lineHeight="27px"
            m="0"
          >
            Budget Overview
          </Text>
        </Flex>

        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          size="sm"
          maxWidth="120px"
          borderRadius="md"
          defaultValue={"monthly"}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </Flex>

      <div id="chart">
        <ReactApexChart
          options={options}
          series={data.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default BudgetOverview;
