import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Textarea,
  Flex,
  VStack,
  Input,
  Box,
  Button,
  Select,
  Radio,
  RadioGroup,
  HStack,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import endpoints from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { toast } from "react-toastify";

const AddNewLead = ({ onClose, isOpen,getData }) => {
  const [formValues, setFormValues] = useState({
    leadName: "",
    leadType: "Person",
    value: "",
    currency: "",
    phoneNumber: "",
    source: "",
    industry: "",
    owner: "",
    description: "",
    visibility: "Public",
    status: "Active",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error on change
  };

  const handleRadioChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error on change
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const res = await CallAPI(endpoints.createLeads, formValues); // Replace with your actual endpoint
        if (res?.code === 200) {
          console.log(res);
          toast.success(res?.message || "Lead added successfully!");
          onClose(); // Close the form/modal
          getData()
          setFormValues({}); // Reset form values if needed
        } else {
          toast.error(res?.message || "Failed to add lead.");
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to add lead due to an error.");
      } finally {
      }
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={{ base: "full", sm: "md" }}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Lead</DrawerHeader>

        <DrawerBody>
          <Stack spacing="24px">
            <Box>
              <FormControl isInvalid={errors.leadName}>
                <FormLabel htmlFor="leadName">
                  Lead Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="leadName"
                  name="leadName"
                  value={formValues.leadName}
                  onChange={handleChange}
                  placeholder="Enter lead name"
                />
                <FormErrorMessage>{errors.leadName}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <FormControl isInvalid={errors.leadType}>
                <FormLabel>
                  Lead Type <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  name="leadType"
                  value={formValues.leadType}
                  onChange={(value) => handleRadioChange("leadType", value)}
                >
                  <HStack
                    spacing={{ base: "8px", md: "24px" }}
                    direction={{ base: "column", md: "row" }}
                  >
                    <Radio value="Person">Person</Radio>
                    <Radio value="Organization">Organization</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{errors.leadType}</FormErrorMessage>
              </FormControl>
            </Box>

            <Flex gap="4" direction={{ base: "column", md: "row" }} wrap="wrap">
              <Box flex="1" minWidth={{ base: "100%", md: "45%" }}>
                <FormControl isInvalid={errors.value}>
                  <FormLabel htmlFor="value">
                    Value <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    id="value"
                    name="value"
                    value={formValues.value}
                    onChange={handleChange}
                    placeholder="Enter value"
                  />
                  <FormErrorMessage>{errors.value}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box flex="1" minWidth={{ base: "100%", md: "45%" }}>
                <FormControl isInvalid={errors.currency}>
                  <FormLabel htmlFor="currency">
                    Currency <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    id="currency"
                    name="currency"
                    value={formValues.currency}
                    onChange={handleChange}
                    placeholder="Enter currency"
                  />
                  <FormErrorMessage>{errors.currency}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Box>
              <FormControl isInvalid={errors.phoneNumber}>
                <FormLabel htmlFor="phoneNumber">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formValues.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                />
                <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            </Box>

            <Flex gap="4" direction={{ base: "column", md: "row" }} wrap="wrap">
              <Box flex="1" minWidth={{ base: "100%", md: "45%" }}>
                <FormControl isInvalid={errors.source}>
                  <FormLabel htmlFor="source">
                    Source <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    id="source"
                    name="source"
                    value={formValues.source}
                    onChange={handleChange}
                    placeholder="Enter source"
                  />
                  <FormErrorMessage>{errors.source}</FormErrorMessage>
                </FormControl>
              </Box>

              <Box flex="1" minWidth={{ base: "100%", md: "45%" }}>
                <FormControl isInvalid={errors.industry}>
                  <FormLabel htmlFor="industry">
                    Industry <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    id="industry"
                    name="industry"
                    value={formValues.industry}
                    onChange={handleChange}
                    placeholder="Enter industry"
                  />
                  <FormErrorMessage>{errors.industry}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Box>
              <FormControl isInvalid={errors.owner}>
                <FormLabel htmlFor="owner">
                  Owner <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="owner"
                  name="owner"
                  value={formValues.owner}
                  onChange={handleChange}
                  placeholder="Select owner"
                >
                  {/* Options for owners can be added here */}
                  <option value="owner1">Owner 1</option>
                  <option value="owner2">Owner 2</option>
                </Select>
                <FormErrorMessage>{errors.owner}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <FormControl isInvalid={errors.description}>
                <FormLabel htmlFor="description">
                  Description <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Textarea
                  id="description"
                  name="description"
                  value={formValues.description}
                  onChange={handleChange}
                  placeholder="Enter description"
                />
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <FormControl isInvalid={errors.visibility}>
                <FormLabel>
                  Visibility <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  name="visibility"
                  value={formValues.visibility}
                  onChange={(value) => handleRadioChange("visibility", value)}
                >
                  <HStack
                    spacing={{ base: "8px", md: "24px" }}
                    direction={{ base: "column", md: "row" }}
                  >
                    <Radio value="Public">Public</Radio>
                    <Radio value="Private">Private</Radio>
                    <Radio value="Select People">Select People</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{errors.visibility}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <FormControl isInvalid={errors.status}>
                <FormLabel>
                  Status <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <RadioGroup
                  name="status"
                  value={formValues.status}
                  onChange={(value) => handleRadioChange("status", value)}
                >
                  <HStack
                    spacing={{ base: "8px", md: "24px" }}
                    direction={{ base: "column", md: "row" }}
                  >
                    <Radio value="Active">Active</Radio>
                    <Radio value="Inactive">Inactive</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{errors.status}</FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" color="#00000099" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleSubmit}>
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddNewLead;
